.shadow-dark-sm {
  box-shadow: $cap-box-shadow-dark-sm !important;
}

.shadow-dark {
  box-shadow: $cap-box-shadow-dark !important;
}

.shadow-dark-lg {
  box-shadow: $cap-box-shadow-dark-lg !important;
}

.shadow-up {
  box-shadow: $cap-box-shadow-up !important;
}
