@use "sass:color"; @import "@frontend/vars.scss";
.disabled-item {
  color: $cap-gray-300 !important;
}

.expanded-item-content {
  top: -$spacer;
  position: absolute;
  white-space: nowrap;
}

.ci-card {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.ci-dropdown-item,
.dropdown-item-link,
.dropdown-item-content {
  color: $cap-gray-600;
  cursor: pointer;
  display: block;
  max-width: 320px;

  &.active {
    color: $cap-primary-700;
  }

  &:hover {
    text-decoration: none;
    &:not(.disabled) {
      background: $cap-gray-100;
    }
  }

  .description {
    white-space: initial;
  }
}

.mobile-selected {
  width: 100% !important;
  top: 0;
  visibility: visible !important;
  transform: unset !important;
}
