@use "sass:map";

@mixin custom-col($name, $value) {
  @each $breakpoint in map.keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .col#{$infix}-#{$name} {
      @extend %grid-column;
    }

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      .col#{$infix}-#{$name} {
        @include make-col($value, 100);
      }
    }
  }
}

.col-custom {
  position: relative;
  width: 100%;
  padding-right: calc($grid-gutter-width / 2);
  padding-left: calc($grid-gutter-width / 2);
}

@include custom-col("fifth", 20);
@include custom-col("eighth", 12.5);
@include custom-col("tenth", 10);

@media print {
  .col-print-1 {
    flex: 0 0 calc(100% / 12 * 1) !important;
    max-width: calc(100% / 12 * 1) !important;
  }

  .col-print-2 {
    flex: 0 0 calc(100% / 12 * 2) !important;
    max-width: calc(100% / 12 * 2) !important;
  }

  .col-print-3 {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }

  .col-print-4 {
    flex: 0 0 calc(100% / 12 * 4) !important;
    max-width: calc(100% / 12 * 4) !important;
  }

  .col-print-5 {
    flex: 0 0 calc(100% / 12 * 5) !important;
    max-width: calc(100% / 12 * 5) !important;
  }

  .col-print-6 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  .col-print-7 {
    flex: 0 0 calc(100% / 12 * 7) !important;
    max-width: calc(100% / 12 * 7) !important;
  }

  .col-print-8 {
    flex: 0 0 calc(100% / 12 * 8) !important;
    max-width: calc(100% / 12 * 8) !important;
  }

  .col-print-9 {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }

  .col-print-10 {
    flex: 0 0 calc(100% / 12 * 10) !important;
    max-width: calc(100% / 12 * 10) !important;
  }

  .col-print-11 {
    flex: 0 0 calc(100% / 12 * 11) !important;
    max-width: calc(100% / 12 * 11) !important;
  }

  .col-print-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
