@use "sass:color"; @import "@frontend/vars.scss";
.interest-tags {
  $expanded-cta-height: 2rem;
  $tag-line-height: 2.5rem;

  position: relative;

  &__cover {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: $expanded-cta-height;
    background: $white;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__wrapper {
    position: relative;
    height: 100%;
  }

  &.expanded {
    overflow: visible;
    min-height: auto;

    &.show-toggle {
      padding-bottom: $tag-line-height;

      &__wrapper {
        padding-bottom: $tag-line-height;
      }
    }
  }

  &.line-1 {
    overflow: hidden;
    max-height: $tag-line-height;

    &.show-toggle {
      max-height: $tag-line-height + $expanded-cta-height;
    }

    .interest-tags__cover {
      top: $tag-line-height;
    }
  }

  &.line-2 {
    overflow: hidden;
    max-height: $tag-line-height * 2;

    &.show-toggle {
      max-height: $tag-line-height * 2 + $expanded-cta-height;
    }
    .interest-tags__cover {
      top: $tag-line-height * 2;
    }
  }

  .ci-tag {
    position: relative;
  }
}
