@use "sass:color"; @import "@frontend/vars.scss";
#partner-buttons {
  .partner-header-container {
    margin-top: -1.75rem;
  }
  .partner-header {
    color: $cap-gray-600;
    background-color: white;
    font-weight: $font-weight-normal;
  }
  :deep(.ci-button-to button img) {
    height: 2rem;
    width: 2rem;
  }
}
