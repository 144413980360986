.ci-dropdown-menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  z-index: 10;

  &:hover {
    background-color: $cap-gray-100;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}
