@use "sass:color"; @import "@frontend/vars.scss";.text-disabled {
  color: $cap-gray-300;
}

.ci-checkbox {
  &--group {
    display: flex;
    padding: 1em 0.5em 0 0;
    align-items: center;

    &.v-center,
    &.no-label:not(.no-spacing) {
      padding: 0 0.5em 0 0;
    }
    &.no-spacing {
      padding-top: 0;
    }

    &.toggle-checkbox {
      padding-top: 0;
      .ci-checkbox--input {
        display: none;
      }
    }
  }

  &--input {
    position: relative;
    appearance: none;
    background: white;
    min-width: 20px;
    min-height: 20px;
    margin-right: 0.5em;
    border-radius: 0.25em;
    border: 1px solid;
    border-color: $cap-gray-300;

    &:not(:disabled) {
      cursor: pointer;

      & ~ .ci-checkbox--toggle {
        &:hover {
          border-color: $cap-gray-600 !important;
        }
      }
    }

    &:hover:not(:disabled) {
      border-color: $cap-gray-600 !important;
    }

    &:disabled {
      opacity: 0.5;

      & ~ .ci-checkbox--toggle {
        background-color: $cap-gray-200;
        border: 1px solid $cap-gray-300;
        cursor: not-allowed;
      }
    }

    &:checked {
      background-color: var(--primary);
      border-color: var(--primary);

      &:hover {
        background-color: $cap-primary-600;
        border-color: $cap-primary-600 !important;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: no-repeat center / 59%;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgOEw0LjUgMTFMMTEgMSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
      }

      & ~ .ci-checkbox--toggle {
        background-color: $cap-primary;
        border-color: $cap-primary;
        &:after {
          background-color: white;
          transform: translateX(20px);
        }
      }
    }

    &:focus {
      outline: none;
      border-color: var(--cyan);
    }
  }

  &--label {
    margin-bottom: 0;
  }

  &flex-label {
    flex: 1;
  }

  &--error {
    color: var(--danger);
    font-size: 0.75rem;
  }

  &--toggle {
    width: 40px;
    height: 20px;
    border-radius: 10px;
    background-color: $cap-gray-100;
    border: 1px solid $cap-gray-300;
    padding: 2px;
    cursor: pointer;
    margin: 0 0 0 0.5rem;

    &:after {
      content: "";
      width: 14px;
      display: block;
      border-radius: 50%;
      height: 14px;
      background-color: $cap-gray-400;
      transition: 0.2s transform ease;
    }
  }
}
