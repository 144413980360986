@use "sass:color";@use "~@fontsource/lato/scss/mixins" as Lato;

// default vars
@import "../vars.scss";
@import "../shared/styles/variables/rootvars.scss";

// load local overrides
@import "styles/variables/bootstrap";
@import "styles/variables/vue-select";

// load local plugins

// load shared plugins
@import "../shared/styles/fonts/mdi/mdi.scss";
@import "../shared/styles/plugins/bootstrap-base.scss";
@import "../shared/styles/plugins/croppie.scss";
@import "../shared/styles/plugins/klaro.scss";
@import "../shared/styles/plugins/algolia.scss";
@import "../shared/styles/plugins/bootstrap-vue.scss";
@import "../shared/styles/plugins/skeleton.scss";
@import "../shared/styles/plugins/modal.scss";
@import "~vue-select/src/scss/vue-select.scss";

// load fonts
@include Lato.faces(
  $subsets: latin,
  $weights: (
    300,
    400,
    700,
    900,
  ),
  $styles: (
    normal,
    italic,
  )
);

// load shared mixins
@import "../shared/styles/mixins/utilities/font-antialiasing.mixin.scss";
@import "../shared/styles/mixins/utilities/text-overflow.mixin.scss";
@import "../shared/styles/mixins/utilities/touch-overflow-scroll.mixin.scss";

// load local styles
@import "styles/app.scss";
@import "styles/print.scss";
@import "styles/layout.scss";
@import "styles/animations/checkmark-circle.scss";
@import "styles/modules/user-settings.scss";
@import "styles/views/articles/article-details.scss";
@import "styles/views/auth/sign_in.scss";
@import "styles/views/layout/print-overlay.scss";
@import "styles/views/registration/registration.scss";
@import "styles/views/start/topics-overview.scss";

// load shared styles
@import "../shared/styles/app.scss";
@import "../shared/styles/atoms/alerts.scss";
@import "../shared/styles/atoms/breadcrumb.scss";
@import "../shared/styles/atoms/buttons.scss";
@import "../shared/styles/atoms/cards.scss";
@import "../shared/styles/atoms/circle.scss";
@import "../shared/styles/atoms/cols.scss";
@import "../shared/styles/atoms/divider.scss";
@import "../shared/styles/atoms/dropdown.scss";
@import "../shared/styles/atoms/forms.scss";
@import "../shared/styles/atoms/helper.scss";
@import "../shared/styles/atoms/image.scss";
@import "../shared/styles/atoms/pagination.scss";
@import "../shared/styles/atoms/progress.scss";
@import "../shared/styles/atoms/ripple.scss";
@import "../shared/styles/atoms/shadows.scss";
@import "../shared/styles/atoms/tables.scss";
@import "../shared/styles/atoms/themes.scss";
@import "../shared/styles/atoms/typography.scss";
@import "../shared/styles/atoms/utilities.scss";
@import "../shared/styles/atoms/vue-select.scss";
@import "../shared/styles/atoms/word-break.scss";
@import "../components/base/forms/base.scss";

// load legacy stuff
@import "../modules/articles/feed/article-feed.component.scss";
@import "../modules/base/forms/form-input.component.scss";
@import "../modules/companies/teaser-list/companies-teaser-list.component.scss";
@import "../modules/companies/users-list/users-list.component.scss";
@import "../modules/user/search/modal.component.scss";

@page {
  margin: 2cm 1.5cm;
}
