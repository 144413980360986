@use "sass:color"; @import "@frontend/vars.scss";
.custom-foldable {
  &__content {
    position: relative;
    &:before {
      pointer-events: none;
      position: absolute;
      height: 0%;
      left: 0;
      bottom: 0;
      width: 100%;
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      content: "";
      transition: 0.2s all ease;
    }

    &.fading {
      &:before {
        height: 60%;
      }
    }
    &.collapsed {
      overflow: hidden;
    }
  }
}
