// style adjustment for print necessary because background colors from pogress bars don't get printed

@media print {
  .progress {
    position: relative;
  }

  .progress:before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    border-bottom: 2rem solid #eee;
  }

  .progress-bar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    border-bottom: 2rem solid $cap-primary;
  }
}
