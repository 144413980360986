@use "sass:color"; @import "@frontend/vars.scss";
// to be used in children components
:deep(.notification-border) {
  border: 1px solid #dadde3;
  border-radius: 0.25rem;
  padding: 1rem;
  width: 100%;
  max-width: 40rem;
  overflow: hidden;

  &:hover {
    box-shadow: 0 3px 8px 0 rgba(64, 67, 78, 0.15) !important;
  }
}

.notification {
  border-bottom: 1px solid $cap-gray-200;
  margin: 0 1rem;

  @include media-breakpoint-up(md) {
    @container (min-width: 361px) {
      margin: 0 1.5rem;
    }
  }

  &-wrapper {
    display: grid;
    grid-template-columns: 3rem minmax(0, 1fr);
    position: relative;
    padding: 1rem 0;

    @include media-breakpoint-up(md) {
      @container (min-width: 361px) {
        padding: 1.5rem 0;
      }
    }
  }

  &-icon {
    font-size: 24px;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 12px;
  }

  &-icon-column {
    color: $cap-black;
    position: relative;

    .mdi {
      transform: translateY(-6px);
    }
  }

  &-content-column {
    max-width: 100%;
  }

  &-dot-indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $primary;
    margin: 8px auto 0;
    flex-shrink: 0;
  }

  &-bar-indicator {
    width: 2px;
    height: 100%;
    background-color: $primary;
    margin: 0 auto;
    flex-shrink: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
    color: $cap-black;
  }

  &-body {
    display: flex;
    flex-direction: column;
    color: $cap-black;
  }
}

.is-unread {
  color: $primary;
}
