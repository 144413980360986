.topic {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);

  &:last-child {
    border-bottom: none;
  }

  .category-label {
    flex-basis: 250px;
  }

  .category-list {
    flex-grow: 1;
  }
}
