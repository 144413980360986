$mdi-font-path: "~@mdi/font/fonts";

@import "~@mdi/font/scss/functions";
@import "~@mdi/font/scss/core";
@import "~@mdi/font/scss/icons";
@import "~@mdi/font/scss/extras";
@import "~@mdi/font/scss/animated";

@font-face {
  font-family: "#{$mdi-font-name}";
  src: url("@frontend/assets/fonts/materialdesignicons-webfont.woff2") format("woff2");
  font-weight: $font-weight-normal;
  font-style: normal;
  font-display: swap;
}

@mixin mdi-pseudo($icon) {
  @extend .mdi-set;

  content: mdi($icon);
}

.mdi {
  display: inline-block;
  vertical-align: middle;
}

.alert {
  .mdi {
    &:before {
      margin: 0 3px 0 -3px;
    }
  }
}

.btn {
  .mdi {
    display: inline-block;

    &:before {
      display: block;
    }

    &:first-child {
      &:before {
        margin-right: calc($input-btn-padding-x / 2);
      }
    }

    &:last-child {
      &:before {
        margin-right: 0;
      }
    }
  }

  &.icon-only {
    .mdi {
      &:before {
        display: block;
      }
    }
  }

  &.mdi {
    &:not(:empty) {
      &:before {
        margin: 0 3px 0 -3px;
      }
    }
  }
}

.breadcrumb-item {
  a,
  span {
    &.mdi {
      &:before {
        margin: 0 2px 0 -2px;
      }
    }
  }
}

.dropdown-item {
  &.mdi {
    &:before {
      margin: 0 8px 0 -10px;
    }
  }
}

.list-group-item {
  &.mdi {
    &:before {
      margin: 0 6px 0 -6px;
    }
  }
}

.modal-title {
  &.mdi {
    &:before {
      margin: 0 4px 0 0;
    }
  }
}

.nav-link {
  &.mdi {
    &:before {
      margin: 0 4px 0 -4px;
    }
  }
}

.navbar-brand {
  &.mdi {
    &:before {
      margin: 0 4px 0 0;
    }
  }
}

a.icon-decoration-none {
  .mdi:before {
    display: inline-block;
    text-decoration: none;
  }
}
