@use "sass:color"; @import "@frontend/vars.scss";.login-form {
  position: relative;
  width: 100%;

  &--is-full {
    .login-form__form-helper-link {
      font-size: 1rem;
    }
  }

  &__content {
    padding: 2rem;

    @include media-breakpoint-down(xs) {
      padding: 1rem;
    }

    &--is-full {
      padding: 0;

      @include media-breakpoint-down(xs) {
        padding: 0;
      }

      #partner-buttons {
        padding-bottom: 2rem;
      }
    }
  }

  &__providers {
    display: flex;
    justify-content: center;
    margin: 0.75rem 0 1.5rem;

    &--is-full {
      margin: 1.5rem 0;
      gap: 1.2rem;
    }
  }

  &__bottom-divider {
    margin: 0.5rem 0;
    background-color: rgba(0, 0, 0, 0.05);
  }

  &__top-divider {
    margin: 0 0 2rem;
    background-color: rgba(0, 0, 0, 0.05);
  }

  &__form {
    position: relative;

    &-button {
      &--is-full {
        width: 100%;
        margin: 2rem 0 0;
        height: 48px !important;
      }
    }

    &-additional {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @media screen and (max-width: 360px) {
        flex-direction: column;
        gap: 1rem;
      }
    }

    &-helper-link {
      font-size: 0.875rem;
    }
  }

  &__header {
    position: relative;

    &-logo {
      width: 140px;
      display: block;
      margin: 0 auto 2rem;
    }

    &-title {
      font-size: 2.5rem;

      @include media-breakpoint-down(lg) {
        font-size: 2rem;
      }

      @include media-breakpoint-down(sm) {
        font-size: 1.75rem;
      }
    }

    &-text {
      font-size: 1.25rem;
    }

    &-title-modal {
      font-size: 2rem;
    }
  }

  &__footer {
    position: relative;
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: $cap-white;
    background-color: $cap-primary-500;

    &--is-full {
      color: $cap-black;
      background-color: $cap-white;

      .login-form__footer {
        &-button {
          width: 100%;
        }
        &-headline {
          font-weight: $font-weight-normal;
        }
      }
    }

    &-headline {
      margin: 0 0 1.5rem;
      text-align: center;
      font-weight: $font-weight-bold;
    }

    &-button {
      position: relative;

      &--is-full {
        width: 100%;
      }
    }
  }
}
