@use "sass:color";

.modal-open {
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.ci-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-modal;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;

  .modal-title {
    margin-bottom: 0;
    line-height: $modal-title-line-height;
  }

  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    border-top: $modal-footer-border-width solid $modal-footer-border-color;
    > * {
      margin: calc($modal-footer-margin-between / 2);
    }

    @include media-breakpoint-down(sm) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1rem;

      > button:first-child:last-child {
        grid-column-start: span 2;
      }
    }
  }

  &-no-footer .modal-body:not(.p-0) {
    padding-bottom: 1.5rem !important;
  }
  &-no-header .modal-body:not(.p-0) {
    padding-top: 1.5rem !important;
  }

  .modal-dialog {
    position: relative;
    pointer-events: none;
    display: flex;
    flex-direction: column;

    .modal.fade & {
      transform: $modal-fade-transform;

      @include transition($modal-transition);
    }

    .modal.show & {
      transform: $modal-show-transform;
    }

    .modal.modal-static & {
      transform: $modal-scale-transform;
    }
  }

  @include media-breakpoint-up(md) {
    padding: calc(#{$ci-header-height} + 1.5rem) 1.5rem 1.5rem;

    .modal-dialog {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: calc(#{$ci-header-height} + 0.5rem) 0.5rem 0.5rem;

    .modal-dialog {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: $modal-content-color;
    pointer-events: auto;
    background-color: $modal-content-bg;
    background-clip: padding-box;
    border: $modal-content-border-width solid $modal-content-border-color;

    @include border-radius($modal-content-border-radius);
    @include box-shadow($modal-content-box-shadow-xs);

    outline: 0;

    &:last-child {
      @include border-bottom-radius($modal-content-inner-border-radius);
    }
  }

  .modal-header {
    padding: 1.5rem;
    display: flex;
    align-items: flex-start; // so the close btn always stays on the upper right corner
    justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends

    @include border-top-radius($modal-content-inner-border-radius);

    .close {
      padding: $modal-header-padding;

      // auto on the left force icon to the right even when there is no .modal-title

      margin: (-$modal-header-padding-y) (-$modal-header-padding-x) (-$modal-header-padding-y) auto;
    }
  }

  .modal-body {
    padding: 0 1.5rem;
    background: linear-gradient(white, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white) 0 100%,
      linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)),
      linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 0.5rem, 100% 0.5rem;
    background-attachment: local, local, scroll, scroll;
  }

  &-size-md {
    @include media-breakpoint-up(md) {
      .modal-title {
        font-size: 1.5rem;
      }
      .modal-dialog {
        width: 500px;
      }
    }
  }

  &-size-lg {
    @include media-breakpoint-only(md) {
      .modal-dialog {
        width: 720px;
      }
    }

    @include media-breakpoint-between(lg, xxl) {
      .modal-dialog {
        width: 952px;
      }
    }

    @include media-breakpoint-up(md) {
      .modal-title {
        font-size: 1.5rem;
      }
    }
  }

  &-expand {
    max-height: 100%;
  }

  &-size-fullscreen {
    .modal-dialog {
      width: 100%;
      margin: 0;
      height: 100vh;
      display: flex;
      max-width: initial;

      :deep(.modal-body > *) {
        height: 100%;
      }
    }
  }

  &-fixed {
    &:not(.ci-modal-size-fullscreen) &:not(.ci-modal-expand) {
      @include media-breakpoint-up(md) {
        .modal-dialog {
          height: 700px;
        }
      }

      @include media-breakpoint-down(sm) {
        .modal-dialog {
          height: 100%;
        }
      }
    }
    .modal-body {
      padding: 0 1.5rem;
      flex-grow: 1;
      overflow-y: auto;
    }

    .modal-content {
      height: 100%;
      overflow: hidden;
    }

    .modal-content,
    .modal-dialog {
      max-height: 100%;
    }
  }

  &-grow {
    .modal-body {
      overflow-y: auto;
    }

    .modal-content {
      height: 100%;
      overflow: hidden;
    }

    .modal-content,
    .modal-dialog {
      max-height: 100%;
    }
  }
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-modal-backdrop;
  width: 100vw;
  height: 100vh;
  background-color: rgba(42, 46, 53, 0.5);

  // Fade for backdrop
  &.fade {
    opacity: 0;
  }
  &.show {
    opacity: $modal-backdrop-opacity;
  }
}
