@use "sass:color"; @import "@frontend/vars.scss";
.ci-loading {
  top: 0;
  left: 0;
  z-index: $cap-loading-z-index;
  transition: 0.2s opacity ease, 0s max-height linear;
  position: fixed;

  &.show {
    opacity: 1;
    max-height: 100vh;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
    max-height: 0;
  }

  .modal-backdrop {
    z-index: 1;
  }

  &__body {
    z-index: 2;
  }
  &__message {
    text-shadow: black 1px 0 1px;
  }
}
