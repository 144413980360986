@use "sass:color"; @import "@frontend/vars.scss";
.toast {
  transform: translateX(200%);
  transition: 0.2s all ease;
  &.show {
    transform: translateX(0%);
  }
}

@mixin toast-variant($theme-color) {
  $background: theme-color-level($theme-color, $alert-bg-level);
  $border: theme-color-level($theme-color, $alert-border-level);
  $color: theme-color-level($theme-color, $alert-color-level);

  background-color: rgba(color.adjust($background, $lightness: 5%), 1);
  border-color: rgba($border, 0.85);
  color: $color;

  a {
    color: $color;
    display: block;
  }

  .toast-header {
    color: $color;
    background-color: rgba($background, 0.85);
    border-bottom-color: rgba($border, 0.85);
    .close {
      color: $color;
    }
  }
}

.info {
  @include toast-variant("primary");
}
.notice {
  @include toast-variant("medium");
}
.success {
  @include toast-variant("success");
}
.warning {
  @include toast-variant("warning");
}
.error {
  @include toast-variant("danger");
}
