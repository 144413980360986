@use "sass:color"; @import "@frontend/vars.scss";
.ci-portfolio-tile {
  position: relative;

  &__content {
    cursor: pointer;
  }

  &__header {
    min-height: 2.25rem;
  }

  &__header_highlight {
    background-color: $cap-purple-700;
    color: white;
    border-radius: 0 0 16px 16px;
  }

  &.start-page {
    margin: 1px 0;
    width: 100% !important;

    .ci-portfolio-tile__header {
      .actions {
        display: none;
      }
    }
  }

  .chart-wrapper {
    height: 200px;
  }

  :deep(.highcharts-range-selector-group) {
    display: none;
  }
}
