.ci-card {
  $card-shadow: 0 3px 8px rgba(64, 67, 78, 0.15);
  $card-border-shadow: 0 0 0 1px $gray-200;

  background: white;
  border-radius: $border-radius;

  &.with-border {
    box-shadow: $card-border-shadow;
  }

  &.with-hover {
    &:hover {
      box-shadow: $card-shadow;
    }

    &.with-border:hover {
      box-shadow: $card-border-shadow, $card-shadow;
    }
  }
}
