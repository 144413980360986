@use "sass:map";

// Color system
$white: $cap-white;
$gray-100: $cap-gray-100;
$gray-200: $cap-gray-200;
$gray-300: $cap-gray-300;
$gray-400: $cap-gray-400;
$gray-500: $cap-gray-500;
$gray-600: $cap-gray-600;
$gray-700: $cap-gray-700;
$gray-800: $cap-gray-800;
$gray-900: $cap-gray-900;
$black: $cap-black;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map.merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
  ),
  $grays
);

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map.merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
  ),
  $colors
);

$primary: $cap-primary;
$secondary: $cap-secondary;
$success: $cap-success;
$info: $cap-info;
$warning: $cap-warning;
$danger: $cap-danger;
$light: $gray-100;
$medium: $gray-300;
$dark: $gray-800;

$theme-colors: $cap-theme-colors;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map.merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "medium": $medium,
    "dark": $dark,
  ),
  $theme-colors
);

$body-bg: $cap-body-bg;
$body-color: $cap-body-color;
$yiq-contrasted-threshold: 200;
$border-color: $gray-200;

// Typography Overrides
$font-weight-lighter: $cap-font-weight-lighter;
$font-weight-light: $cap-font-weight-light;
$font-weight-normal: $cap-font-weight-normal;
$font-weight-medium: $cap-font-weight-medium;
$font-weight-semi: $cap-font-weight-semi;
$font-weight-bold: $cap-font-weight-bold;
$font-weight-bolder: $cap-font-weight-bolder;

$font-weight-base: $font-weight-normal;

$line-height-base: 1.5;
$line-height-lg: 1.5;
$line-height-md: 1.25;
$line-height-sm: 1.5;

$font-size-base: 1rem;
$font-size-xs: $font-size-base * 0.75;
$font-size-sm: $font-size-base * 0.875;
$font-size-md: $font-size-base * 1.12;
$font-size-lg: $font-size-base * 1.25;

$spacer: $cap-spacer;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map.merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 2,
    ),
    6: (
      $spacer * 3,
    ),
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map.merge(
  (
    5: 5%,
    10: 10%,
    15: 15%,
    20: 20%,
    25: 25%,
    30: 30%,
    35: 35%,
    40: 40%,
    45: 45%,
    50: 50%,
    55: 55%,
    60: 60%,
    65: 65%,
    70: 70%,
    75: 75%,
    80: 80%,
    85: 85%,
    90: 90%,
    95: 95%,
    100: 100%,
    auto: auto,
  ),
  $sizes
);

$body-bg: $cap-body-bg;
$body-color: $cap-body-color;

$grid-breakpoints: $cap-grid-breakpoints;

@include _assert-ascending($cap-grid-breakpoints, "$cap-grid-breakpoints");
@include _assert-starts-at-zero($cap-grid-breakpoints, "$cap-grid-breakpoints");

$grid-gutter-width: $cap-grid-gutter-width;

$caret-width: $cap-caret-width;
$caret-vertical-align: $cap-caret-vertical-align;
$caret-spacing: $cap-caret-spacing;

$font-family-sans-serif: $cap-font-family-sans-serif;
$headings-font-weight: $cap-headings-font-weight;

$table-accent-bg: rgba(243, 244, 247, 0.5);
$table-head-bg: rgba($black, 0.03);
$btn-font-weight: $font-weight-semi;
$btn-border-radius: $cap-border-radius;
$btn-border-radius-sm: $cap-border-radius-sm;
$btn-border-radius-lg: $cap-border-radius-lg;

$dropdown-border-radius: $cap-border-radius-lg;

$modal-content-border-color: transparent;
$modal-content-border-width: 0;
$modal-inner-padding: 1.25rem;
$modal-header-padding-y: 1.25rem;
$modal-header-padding-x: 1.25rem;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x 0;

$breadcrumb-padding-x: 0;
$breadcrumb-bg: none;
$breadcrumb-divider-color: $cap-primary;
$breadcrumb-active-color: $gray-700;
$breadcrumb-divider: url("~frontend/assets/rounded-caret.svg") !default;

$carousel-control-color: $cap-carousel-control-color;
$carousel-caption-color: $cap-carousel-caption-color;

$pagination-border-width: 0;
$pagination-border-color: transparent;
$pagination-hover-border-color: transparent;
$pagination-active-border-color: transparent;
$pagination-disabled-color: $gray-400;
$pagination-disabled-border-color: transparent;
