.user-settings {
  &__title {
    margin-top: $spacer * 2;
    margin-bottom: $spacer * 4;
    color: $cap-gray-700;

    @include media-breakpoint-up(md) {
      margin-top: $spacer * 6;
    }
  }
}
