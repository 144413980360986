@use "sass:color"; @import "@frontend/vars.scss";
.lazy-imgix {
  margin: 0;

  &,
  picture {
    background-color: $gray-100;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  img {
    max-width: 100%;
    width: 100%;
  }
}
