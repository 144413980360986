@mixin divider-margin($value) {
  margin-top: $value;
  margin-bottom: $value;

  &.vertical {
    margin: 0 $value;
  }
}

@mixin divider($base: 1rem) {
  @include divider-margin($base);

  &.line {
    @include divider-margin($base * 0);
  }

  &.xs {
    @include divider-margin($base * 0.5);
  }

  &.sm {
    @include divider-margin($base * 0.75);
  }

  // md => default

  &.lg {
    @include divider-margin($base * 1.5);
  }

  &.xl {
    @include divider-margin($base * 2);
  }
}

hr.divider {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);

  @include divider(1rem);

  &.vertical {
    border-top: none;
    border-right-width: 1px;
    height: 100%;
    width: 1px;
  }

  &.invisible {
    border-color: rgba(0, 0, 0, 0);
  }

  &.dark {
    border-color: rgba(0, 0, 0, 0.1);
  }
  &.light {
    border-color: rgba(255, 255, 255, 0.1);
  }
}

@include media-breakpoint-down(sm) {
  hr.divider {
    @include divider(0.75rem);
  }
}
