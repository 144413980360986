@use "sass:color"; @import "@frontend/vars.scss";
body {
  // if there is a child with sticky class
  // make the portal itself sticky
  .vue-portal-target:has(> .sticky) {
    position: sticky;
    top: $ci-header-height;

    // portal target has to be on top e.g. for header
    // so that the content scrolls below
    z-index: 10;
  }
}
