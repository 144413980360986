.alert.fixed-top,
.alert.fixed-bottom {
  right: initial;
  left: initial;
  z-index: 1000;

  .close {
    top: -2px;
    color: inherit;
    font-size: 2rem !important;
  }
}
