img {
  &[lazy] {
    &:not([lazy~="loaded"]) {
      width: 100px;
      height: 100px;
    }
  }
}

[lazy] {
  &:not([lazy~="loaded"]) {
    position: relative;
    background: $body-bg;
    &:before {
      @extend .mdi;

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      font-size: 4rem !important;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $cap-gray-300;
    }
  }

  &[lazy~="loading"] {
    &:before {
      animation: centered-spin 2s infinite linear;
      border-radius: 50%;
      border: 6px solid $cap-gray-300;
      border-top-color: transparent;
      content: "";
      width: 4rem;
      height: 4rem;
    }
  }

  &[lazy~="error"] {
    &:before {
      width: 6rem;
      height: 6rem;
      content: "\F0156";
    }
  }
}

@keyframes centered-spin {
  0% {
    transform: translateY(-50%) translateX(-50%) rotate(0deg);
  }
  100% {
    transform: translateY(-50%) translateX(-50%) rotate(359deg);
  }
}

.bg-center {
  background-position: center;
}

.bg-contain {
  background-size: contain;
  background-repeat: no-repeat;
}
