.breadcrumb {
  font-size: $font-size-base;

  @include media-breakpoint-up(md) {
    margin-top: $spacer * 2;
  }
}

.breadcrumb-item a,
.breadcrumb-item span {
  color: $breadcrumb-active-color;
}
