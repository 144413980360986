@use "sass:color"; @import "@frontend/vars.scss";
.ci-user-avatar {
  position: relative;

  .avatar {
    overflow: hidden;

    &.avatar-available {
      background-color: $gray-100;
    }

    &.sidebar-avatar {
      :deep(svg circle) {
        stroke: transparent;
      }
    }

    &.circle.upload {
      // not 144 because of overlapping with the border in the avatar upload component
      --size: 135px;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }

  .avatar-icon {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: $primary;
    position: absolute;
    right: -9px;
    box-shadow: 0 0 0 3px white;
    top: -9px;

    &:hover {
      background: $cap-primary-600;
    }
  }

  .verified-badge {
    position: absolute;
    right: -5px;
    bottom: -5px;

    &.xs,
    &.sm,
    &.md {
      height: 16px;
      width: 16px;
    }

    &.md {
      right: -2px !important;
      bottom: -2px !important;
      width: 1rem !important;
      height: 1rem !important;
    }

    &.xs,
    &.sm {
      right: -5px !important;
      bottom: -5px !important;
    }

    &.xxl {
      right: 5px !important;
      bottom: 0 !important;
      width: 2rem !important;
      height: 2rem !important;
    }

    &.xxxl,
    &.xxxxl {
      right: 10px !important;
      bottom: 10px !important;
      width: 3rem !important;
      height: 3rem !important;
    }
  }
}
