#app {
  min-height: $ci-full-page-height;
  max-width: $cap-app-max-width;

  &[v-cloak] > * {
    display: none;
  }

  &[v-cloak] {
    background: $body-bg;
  }

  &:not([v-cloak]) {
    animation-name: example;
    animation-duration: 0.1s;
  }
}

@keyframes autofill {
  to {
    color: white;
    background: $cap-gray-800;
  }
}

.table-striped-alter {
  tr:nth-of-type(even) {
    background-color: $table-accent-bg;
  }
  .limit-desktop-base-data-table-heading-width {
    @include media-breakpoint-up(xl) {
      width: 14rem;
    }
  }
}

.table-no-top-border {
  thead > tr > th {
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
  }
}

@include media-breakpoint-down(xs) {
  .no-gutters-xs {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

body {
  padding-top: $ci-header-height;

  @include media-breakpoint-down(md) {
    &.nav-open {
      overflow: hidden;
    }
  }
  .region-switcher {
    background-color: $ci-footer-background-highlight-color;
    color: $cap-white;
  }
}
