#users-list {
  .ci-user-tile:first-of-type {
    margin-left: 0 !important;
  }
  .carousel-caption {
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    padding: 0;
    z-index: 3;
  }
}
