@use "sass:color"; @import "@frontend/vars.scss";
.ci-footer {
  background: $ci-footer-background-color;

  &__links-wrapper {
    background: $ci-footer-background-highlight-color;
  }

  &__logo,
  &__logo img {
    height: $ci-footer-logo-height;
  }

  .tiktok-logo {
    height: 36px;
    width: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    svg {
      width: 23px;
    }
  }

  .break {
    @include media-breakpoint-up(md) {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      flex-basis: 100%;
      height: 0;
    }
  }
}
