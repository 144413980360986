@mixin animation($value) {
  animation: unquote($value);
}

/* stylelint-disable keyframes-name-pattern */
@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}
/* stylelint-enable keyframes-name-pattern */
