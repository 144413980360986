@use "sass:color"; @import "@frontend/vars.scss";
@import "@frontend/shared/styles/mixins/utilities/animation.mixin.scss";

.ci-product-tile {
  position: relative;

  &__chart-content {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &__content {
    position: relative;

    &--is-footer {
      margin: auto 0 0;
    }
  }

  &__header {
    // height of header when we have content in recommendation context
    min-height: 17px;
  }

  &__actions {
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
    z-index: 2;
    margin-top: 8px;
  }

  &__action {
    &--checkbox {
      margin-right: -6px;
      margin-top: 2px;
    }

    &--bookmark {
      margin-top: -8px;
    }
  }

  &__chart {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.3s ease;
    width: 100%;
    z-index: 0;

    &--watchlist {
      position: absolute;
      height: 150px;
      margin: 0;
      opacity: 1;
      top: 4rem;
    }

    &-empty {
      margin: 0 auto;
      pointer-events: none;
      display: block !important;
    }

    &--is-empty {
      display: flex;
      align-items: center;

      @include media-breakpoint-down(sm) {
        height: auto;
        padding: 1.5rem 0;
      }
    }
  }

  &.ci-teaser-tile {
    footer {
      line-height: 1.15;
    }
  }

  &__perf-inception-label {
    font-weight: $font-weight-bold;
    color: $cap-black;
  }

  &__perf-inception-value {
    &.text-primary {
      color: $cap-primary-700 !important;
    }

    &.text-danger {
      color: $cap-danger-900 !important;
    }
  }

  &.tile-context-watchlist {
    .ci-product-tile {
      &__content {
        &--is-footer {
          position: absolute;
          bottom: 0;
        }
      }
    }

    &.ci-card {
      height: 20rem;
    }
  }
}

.fade-enter-active,
.fade-enter-to {
  @include animation("fadeIn 0.5s cubic-bezier(0.75, 0, 0.125, 1) forwards -0.25s");

  @include keyframes("fadeIn") {
    0% {
      transform: translateY(-100%);
    }

    100% {
      transform: translateY(0);
    }
  }
}

/* stylelint-disable */
h3.headline {
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
/* stylelint-enable */

.mediathek-label {
  left: 1.5rem;
}
