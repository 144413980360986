@import "../mixins/extends/ghost-button.mixin.scss";
@import "../mixins/extends/outline-button.mixin.scss";

// Button with cursor = pointer
.btn-cursor,
.cursor-pointer {
  cursor: pointer;
}

.link-button {
  white-space: nowrap;
}

.btn-icon {
  display: flex;
  align-items: center;
}

// Ghost buttons
@each $color, $value in $theme-colors {
  .btn-ghost-#{$color} {
    @include button-ghost-variant($value, $value);
  }
}

// Outline buttons
@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value, $value);
  }
}

.btn-ghost-col {
  flex-direction: column;
  justify-content: center;
  color: $gray-600;
  height: 70px;

  &:hover {
    color: $primary;
  }

  .mdi {
    display: block;
    font-size: $font-size-lg;

    &:before {
      margin: 0 !important;
    }
  }

  &.active {
    color: $primary;

    .mdi {
      color: $primary;
    }
  }

  span:nth-child(2) {
    font-size: $font-size-sm;
  }
}

.disabled,
[disabled] {
  cursor: not-allowed;
}

.dropdown.show .btn-ghost-dropdown,
.btn-ghost-dropdown:hover {
  background: $body-bg;
}
.btn-ghost-dropdown {
  background: transparent;
}

.dropdown-toggle.icon {
  padding: 0;
}

.fab-placeholder {
  --size: #{$ci-button-sm-size * $ci-button-fab-multiplier};
  --height: calc(var(--size) + 2rem);

  height: var(--height);

  &.xs {
    --size: #{($ci-button-xs-size * $ci-button-fab-multiplier)};
  }

  &.md {
    --size: #{$ci-button-md-size * $ci-button-fab-multiplier};
  }
}

.btn-height-xs {
  height: $ci-button-xs-size;
}
.btn-height-sm {
  height: $ci-button-sm-size;
}
.btn-height-md {
  height: $ci-button-md-size;
}
.btn-min-height-xs {
  min-height: $ci-button-xs-size;
}
.btn-min-height-sm {
  min-height: $ci-button-sm-size;
}
.btn-min-height-md {
  min-height: $ci-button-md-size;
}
