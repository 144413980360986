@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

.ripple-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: $border-radius;
  pointer-events: none;

  .ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    transform-origin: center;
    pointer-events: none;
    &.show {
      animation: ripple 600ms linear;
    }
  }
}
