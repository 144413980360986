.ci-form--wrapper {
  --ci-form-label-weight: #{$ci-form-label-font-weight};
  --ci-form-label-color: #{$gray-600};
  --ci-form-group-border-color: transparent;
  --ci-form-group-height: #{$ci-form-group-height-md};
  --ci-form-label-height: #{$ci-form-group-height-md};
  --ci-form-input-font-size: #{$ci-form-input-font-size-md};
  --ci-form-label-font-size: #{$ci-form-input-font-size-md};
  --ci-form-label-height-focused: #{$ci-form-label-floating-height-md};
  --ci-form-label-font-size-focused: #{$ci-form-label-floating-font-size-md};
  --ci-form-icon-fontsize: #{$ci-form-font-size};
  --ci-form-icon-width: #{$ci-form-icon-width};

  .ci-form--group {
    background-color: var(--light);
    border-radius: $ci-form-border-radius;
    border-bottom: $ci-form-border-bottom-size solid var(--ci-form-group-border-color);
    height: var(--ci-form-group-height);
    display: flex;
    position: relative;

    &--inner {
      position: relative;
      flex-grow: 1;
      width: 100%;
      height: 100%;
    }

    &.size-sm {
      --ci-form-group-height: #{$ci-form-group-height-sm};
      --ci-form-label-height: #{$ci-form-group-height-sm};
      --ci-form-input-font-size: #{$ci-form-input-font-size-sm};
      --ci-form-label-font-size: #{$ci-form-input-font-size-sm};
      --ci-form-label-height-focused: #{$ci-form-label-floating-height-sm};
      --ci-form-label-font-size-focused: #{$ci-form-label-floating-font-size-sm};

      &:focus-within,
      &:focus-within.populated,
      &.populated {
        .ci-form--label {
          display: none;
        }
      }

      .ci-form--input {
        padding-top: 0 !important;
      }
    }

    &.size-lg {
      --ci-form-group-height: #{$ci-form-group-height-lg};
      --ci-form-label-height: #{$ci-form-group-height-lg};
      --ci-form-input-font-size: #{$ci-form-input-font-size-lg};
      --ci-form-label-font-size: #{$ci-form-input-font-size-lg};
      --ci-form-label-height-focused: #{$ci-form-label-floating-height-lg};
      --ci-form-label-font-size-focused: #{$ci-form-label-floating-font-size-lg};
      --ci-form-icon-fontsize: #{$ci-form-font-size * 1.25};
      --ci-form-icon-width: #{$ci-form-icon-width * 1.25};
    }

    &.no-label {
      height: $ci-form-group-height-md * 0.8;
      .ci-form--label {
        display: none;
      }
      .ci-form--input {
        padding-top: 0 !important;
      }
    }

    &.bordered {
      border: $ci-form-full-border;
      &:focus-within,
      &:focus-within.populated {
        --ci-form-group-border-color: var(--primary);
        --ci-form-label-color: var(--primary);

        border-bottom: 2px solid var(--ci-form-group-border-color);
      }

      &.populated {
        --ci-form-group-border-color: var(--muted);

        border-bottom: 2px solid var(--ci-form-group-border-color);
      }

      &.invalid {
        --ci-form-group-border-color: var(--danger);
        --ci-form-label-color: var(--danger);

        border-bottom: 2px solid var(--ci-form-group-border-color);
      }
    }

    // States
    &.disabled {
      opacity: $ci-form-disabled-opacity;
      cursor: not-allowed;

      + .ci-form--error,
      + .ci-form--hint {
        opacity: $ci-form-disabled-opacity;
      }
    }

    &:focus-within,
    &:focus-within.populated {
      --ci-form-group-border-color: var(--primary);
      --ci-form-label-color: var(--primary);

      + .ci-form--error,
      + .ci-form--hint {
        color: $primary;
      }
    }

    &.populated {
      --ci-form-group-border-color: var(--muted);
    }

    &:focus-within,
    &:focus-within.populated,
    &.populated {
      --ci-form-label-font-size: var(--ci-form-label-font-size-focused);
      --ci-form-label-weight: #{$ci-form-label-floating-font-weight};
      --ci-form-label-height: var(--ci-form-label-height-focused);

      .ci-form--label {
        display: flex;
        align-items: flex-end;
        line-height: normal;
      }
    }

    &.has-leading-icon {
      .ci-form--label,
      .ci-form--input,
      .ci-form--input.v-select {
        padding-left: var(--ci-form-icon-width) !important;

        &.v-select {
          input,
          .vs__selected {
            padding-left: 0;
          }
        }
      }
    }

    &.has-trailing-icon {
      .ci-form--label,
      .ci-form--input,
      .ci-form--input.v-select {
        padding-right: var(--ci-form-icon-width);

        &.v-select input {
          padding-right: 0;
        }
      }
    }

    &.invalid {
      --ci-form-group-border-color: var(--danger);
      --ci-form-label-color: var(--danger);

      + .ci-form--error,
      + .ci-form--hint {
        color: $danger;
      }
    }

    .ci-form--leading-icon,
    .ci-form--trailing-icon {
      flex-shrink: 0;
      height: 100%;
      width: var(--ci-form-icon-width);
      position: absolute;
      font-size: var(--ci-form-icon-fontsize);
      text-align: center;
    }
    .ci-form--leading-icon {
      left: 0;
      pointer-events: none;

      &.has-leading-fn {
        pointer-events: initial;
        z-index: 10;
      }
    }
    .ci-form--trailing-icon {
      right: 0;
      pointer-events: none;

      &.has-trailing-fn {
        pointer-events: initial;
      }
    }

    .ci-form--input {
      color: $cap-black;
      font-size: var(--ci-form-input-font-size);
      border-width: 0;
      width: 100%;
      background: transparent;
      line-height: 1;
      height: 100%;
      font-style: inherit;
      padding: $ci-form-label-floating-height-md 1rem 0 1rem;

      &::placeholder {
        color: $text-muted;
      }

      &:focus {
        outline: none;
      }

      &[type="number"] {
        padding-right: 0;
      }
    }

    .ci-form--label {
      font-size: var(--ci-form-label-font-size);
      font-weight: var(--ci-form-label-weight);
      color: var(--ci-form-label-color);
      position: absolute;
      top: 0;
      left: 0;
      height: var(--ci-form-label-height);
      margin-bottom: 0;
      padding: 0 1rem;
      pointer-events: none;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: var(--ci-form-label-height);
    }
  }

  .ci-form--error,
  .ci-form--hint {
    font-size: $ci-form-hint-font-size;
    font-weight: $font-weight-semi;
    line-height: $ci-form-hint-line-height;
    min-height: $ci-form-hint-line-height;
    padding: $ci-form-hint-padding;
    display: block;

    &:empty:before {
      content: "\200b"; // unicode zero width space character
    }
  }
}
