@use "sass:color"; @import "@frontend/vars.scss";
.ci-form--wrapper .ci-form--group {
  :deep(.ci-form--input.v-select) {
    padding: 0;

    input {
      padding: $ci-form-label-floating-height-md 1rem 0 1rem;
      margin-top: 0;
    }

    .vs__dropdown-toggle {
      height: 100%;
      padding-bottom: 0;
      background: transparent !important;
    }

    .vs__selected,
    .vs__search {
      padding: $ci-form-label-floating-height-md 1rem 0 1rem;
      height: 100%;
      margin: 0;
      border: none;
      display: flex;
    }

    &.vs--open,
    &.vs--searching {
      .vs__selected {
        opacity: 0;
        position: absolute;
      }
    }

    &.no-label {
      .vs__selected {
        padding-top: 0;
        color: $cap-gray-600;
      }
      &.vs--open,
      &.vs--searching {
        input {
          padding-top: 0;
        }
      }
    }

    .vs__actions {
      height: 100% !important;
      padding: 0;
      .vs__open-indicator {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 0.5rem;
      }
      .vs__clear {
        height: 100%;
        display: flex;
        align-items: center;
        fill: $gray-600;
      }
    }

    .vs__selected-options {
      padding: 0;
      height: auto;
      width: calc(100% - 46px);
    }

    .vs__dropdown-option {
      padding: 0.5rem 1rem;
      position: relative;

      .truncated-hover {
        display: none;
        position: absolute;
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
        font-size: 12px;
        left: 0.5rem;
        padding: 0.25rem 0.5rem;
        transform: translateY(-25px);
        border-radius: 4px;
        right: 0.5rem;
        white-space: pre-line;
        bottom: 0;
      }

      &:first-child {
        .truncated-hover {
          bottom: -3.5rem;
          z-index: 1;
        }
      }

      @include media-breakpoint-up(md) {
        &.vs__dropdown-option--highlight .truncated + .truncated-hover {
          display: block;
        }
      }
    }
  }
}
