.sign-in-page {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  min-height: calc(#{$ci-full-page-height} - #{$ci-content-padding-desktop * 2});

  @include media-breakpoint-only(md) {
    min-height: calc(#{$ci-full-page-height} - #{$ci-content-padding-tablet * 2});
  }

  @include media-breakpoint-down(sm) {
    min-height: calc(#{$ci-full-page-height} - #{$ci-content-padding-mobile * 2});
  }

  &__content {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  &__form {
    width: 100%;
    max-width: 43rem;
    margin: 0 auto;
    display: block;

    @include media-breakpoint-down(lg) {
      max-width: 512px;
    }

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }

  &__info {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__image {
    display: block;
    width: 100%;
    object-fit: cover;
    height: 100%;
    object-position: 15%;
  }
}
