@use "sass:color"; @import "@frontend/vars.scss";
.ci-header__main-nav__child {
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  .title,
  .description {
    color: white;
  }
  &.active {
    .title,
    .description {
      color: $primary;
    }
  }
}
