$input-font-weight: $cap-input-font-weight;
$input-bg: $cap-input-bg;
$input-disabled-bg: $input-bg;

$input-border-color: $cap-input-border-color;
$input-box-shadow: $cap-input-box-shadow;

$input-border-radius: $cap-input-border-radius;
$input-border-radius-lg: $cap-input-border-radius-lg;
$input-border-radius-sm: $cap-input-border-radius-sm;

$input-focus-bg: $cap-input-focus-bg;
$input-focus-box-shadow: $cap-input-focus-box-shadow;

$custom-select-box-shadow: none;
$custom-select-focus-box-shadow: none;
