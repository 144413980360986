/** COLORS **/
$cap-primary-900: #164139;
$cap-primary-800: #1c5347;
$cap-primary-700: #2a7c6b;
$cap-primary-600: #38a58f;
$cap-primary-500: #51c4ac;
$cap-primary-400: #8ad8c8;
$cap-primary-300: #b5eadf;
$cap-primary-200: #d8f4ee;
$cap-primary-100: #f2fbf9;

$cap-primary-colors: (
  "100": $cap-primary-100,
  "200": $cap-primary-200,
  "300": $cap-primary-300,
  "400": $cap-primary-400,
  "500": $cap-primary-500,
  "600": $cap-primary-600,
  "700": $cap-primary-700,
  "800": $cap-primary-800,
  "900": $cap-primary-900,
);

$cap-danger-900: #850101;
$cap-danger-800: #a60a0a;
$cap-danger-700: #c81e1e;
$cap-danger-600: #e14344;
$cap-danger-500: #ec7072;
$cap-danger-400: #f09295;
$cap-danger-300: #f3b6ba;
$cap-danger-200: #ffdfe2;
$cap-danger-100: #fff2f4;

$cap-danger-colors: (
  "100": $cap-danger-100,
  "200": $cap-danger-200,
  "300": $cap-danger-300,
  "400": $cap-danger-400,
  "500": $cap-danger-500,
  "600": $cap-danger-600,
  "700": $cap-danger-700,
  "800": $cap-danger-800,
  "900": $cap-danger-900,
);

$cap-warning-900: #976000;
$cap-warning-800: #bf7900;
$cap-warning-700: #d18d16;
$cap-warning-600: #df9f2f;
$cap-warning-500: #f5bb54;
$cap-warning-400: #ffcc73;
$cap-warning-300: #ffdc9f;
$cap-warning-200: #ffebc9;
$cap-warning-100: #fff7ea;

$cap-warning-colors: (
  "100": $cap-warning-100,
  "200": $cap-warning-200,
  "300": $cap-warning-300,
  "400": $cap-warning-400,
  "500": $cap-warning-500,
  "600": $cap-warning-600,
  "700": $cap-warning-700,
  "800": $cap-warning-800,
  "900": $cap-warning-900,
);

$cap-success-900: #034903;
$cap-success-800: #066806;
$cap-success-700: #318031;
$cap-success-600: #539f53;
$cap-success-500: #70ba70;
$cap-success-400: #97d497;
$cap-success-300: #bbe2bb;
$cap-success-200: #d8eed8;
$cap-success-100: #eef8ee;

$cap-success-colors: (
  "100": $cap-success-100,
  "200": $cap-success-200,
  "300": $cap-success-300,
  "400": $cap-success-400,
  "500": $cap-success-500,
  "600": $cap-success-600,
  "700": $cap-success-700,
  "800": $cap-success-800,
  "900": $cap-success-900,
);

$cap-pink-900: #791f3b;
$cap-pink-800: #902949;
$cap-pink-700: #ab395f;
$cap-pink-600: #cb4072;
$cap-pink-500: #d0648a;
$cap-pink-400: #de84a6;
$cap-pink-300: #eaafc6;
$cap-pink-200: #f5d5e1;
$cap-pink-100: #fcf0f5;

$cap-pink-colors: (
  "100": $cap-pink-100,
  "200": $cap-pink-200,
  "300": $cap-pink-300,
  "400": $cap-pink-400,
  "500": $cap-pink-500,
  "600": $cap-pink-600,
  "700": $cap-pink-700,
  "800": $cap-pink-800,
  "900": $cap-pink-900,
);

$cap-blue-900: #001342;
$cap-blue-800: #112459;
$cap-blue-700: #1d3474;
$cap-blue-600: #2f488d;
$cap-blue-500: #465fa6;
$cap-blue-400: #627bbe;
$cap-blue-300: #8ea3dd;
$cap-blue-200: #bfcef5;
$cap-blue-100: #ebf0ff;

$cap-blue-colors: (
  "100": $cap-blue-100,
  "200": $cap-blue-200,
  "300": $cap-blue-300,
  "400": $cap-blue-400,
  "500": $cap-blue-500,
  "600": $cap-blue-600,
  "700": $cap-blue-700,
  "800": $cap-blue-800,
  "900": $cap-blue-900,
);

$cap-purple-900: #3d1646;
$cap-purple-800: #4d1c5a;
$cap-purple-700: #642972;
$cap-purple-600: #8a4c99;
$cap-purple-500: #a470b0;
$cap-purple-400: #bd95c8;
$cap-purple-300: #d8b9df;
$cap-purple-200: #e8d5ed;
$cap-purple-100: #f7eefa;

$cap-purple-colors: (
  "100": $cap-purple-100,
  "200": $cap-purple-200,
  "300": $cap-purple-300,
  "400": $cap-purple-400,
  "500": $cap-purple-500,
  "600": $cap-purple-600,
  "700": $cap-purple-700,
  "800": $cap-purple-800,
  "900": $cap-purple-900,
);

$cap-yellow-900: #922b00;
$cap-yellow-800: #b03c0c;
$cap-yellow-700: #d35b29;
$cap-yellow-600: #ef6e39;
$cap-yellow-500: #ff8b5b;
$cap-yellow-400: #ffa784;
$cap-yellow-300: #ffbfa5;
$cap-yellow-200: #ffdaca;
$cap-yellow-100: #ffeee7;

$cap-yellow-colors: (
  "100": $cap-yellow-100,
  "200": $cap-yellow-200,
  "300": $cap-yellow-300,
  "400": $cap-yellow-400,
  "500": $cap-yellow-500,
  "600": $cap-yellow-600,
  "700": $cap-yellow-700,
  "800": $cap-yellow-800,
  "900": $cap-yellow-900,
);

$cap-turquoise-900: #003945;
$cap-turquoise-800: #004655;
$cap-turquoise-700: #006377;
$cap-turquoise-600: #00849f;
$cap-turquoise-500: #299fb8;
$cap-turquoise-400: #5cbdd0;
$cap-turquoise-300: #91d6e4;
$cap-turquoise-200: #c4eaf1;
$cap-turquoise-100: #e9f7fa;

$cap-turquoise-colors: (
  "100": $cap-turquoise-100,
  "200": $cap-turquoise-200,
  "300": $cap-turquoise-300,
  "400": $cap-turquoise-400,
  "500": $cap-turquoise-500,
  "600": $cap-turquoise-600,
  "700": $cap-turquoise-700,
  "800": $cap-turquoise-800,
  "900": $cap-turquoise-900,
);

$cap-gray-100: #f3f4f7;
$cap-gray-200: #dadde3;
$cap-gray-300: #bec3cd;
$cap-gray-400: #a1a5b4;
$cap-gray-500: #808696;
$cap-gray-600: #677180;
$cap-gray-700: #525b69;
$cap-gray-800: #40434e;
$cap-gray-900: $cap-gray-800;

$cap-gray-colors: (
  "100": $cap-gray-100,
  "200": $cap-gray-200,
  "300": $cap-gray-300,
  "400": $cap-gray-400,
  "500": $cap-gray-500,
  "600": $cap-gray-600,
  "700": $cap-gray-700,
  "800": $cap-gray-800,
  "900": $cap-gray-900,
);

$cap-primary: $cap-primary-500;
$cap-secondary: $cap-blue-800;
$cap-success: $cap-success-800;
$cap-info: $cap-purple-600;
$cap-warning: $cap-warning-800;
$cap-danger: $cap-danger-700;
$cap-white: white;
$cap-black: #2a2e35;
$cap-text: $cap-gray-600;
$cap-muted: #9ca2b4;

/* stylelint-disable */
:export {
  chartPrimary: $cap-primary;
  chartPrimaryGradient: $cap-primary-400;
  chartDanger: $cap-danger;
  chartDangerGradient: $cap-danger-400;
  chartText: $cap-text;
  chartMuted: $cap-muted;
  chartDark: $cap-black;
  chartLightGray: $cap-gray-200;
  chartColors0: $cap-blue-800;
  chartColors1: $cap-turquoise-600;
  chartColors2: $cap-purple-600;
  chartColors3: $cap-pink-600;
  chartColors4: $cap-yellow-500;
  chartColors5: $cap-gray-600;
  chartColors6: $cap-primary-400;
  chartColors7: $cap-blue-400;
  chartColors8: $cap-turquoise-400;
  chartColors9: $cap-purple-400;
  chartColors10: $cap-pink-400;
  chartColors11: $cap-yellow-400;
  chartColors12: $cap-gray-400;
  chartColors13: $cap-primary-700;
  chartColors14: $cap-blue-700;
  chartColors15: $cap-turquoise-700;
  chartColors16: $cap-purple-700;
  chartColors17: $cap-pink-700;
  chartColors18: $cap-yellow-700;
  chartColors19: $cap-gray-700;
  chartColors20: $cap-primary-200;
  chartColors21: $cap-blue-200;
  chartColors22: $cap-turquoise-200;
  chartColors23: $cap-purple-200;
  chartColors24: $cap-pink-200;
  chartColors25: $cap-yellow-200;
  chartColors26: $cap-gray-200;
  donutDisabled: $cap-gray-400;
  donutEmpty: $cap-gray-100;
  donutColors0: $cap-primary-800;
  donutColors1: $cap-primary-700;
  donutColors2: $cap-primary-600;
  donutColors3: $cap-primary-500;
  donutColors4: $cap-primary-400;
  donutColors5: $cap-primary-300;
  donutColors6: $cap-primary-200;
  donutColors7: $cap-primary-100;
  donutColors8: $cap-black;
  donutColors9: $cap-gray-800;
  donutColors10: $cap-gray-700;
  donutColors11: $cap-gray-600;
  donutColors12: $cap-gray-500;
  donutColors13: $cap-gray-400;
}
/* stylelint-enable */

$cap-grid-breakpoints: (
  xxs: 0,
  xs: 360px,
  sm: 480px,
  md: 768px,
  lg: 1000px,
  xl: 1300px,
  xxl: 1600px,
);

$cap-body-bg: $cap-gray-100; // #f3f4f7;
$cap-body-color: $cap-gray-800;

$cap-theme-colors: (
  "medium": $cap-gray-400,
  "muted": $cap-gray-300,
);

/** TYPOGRAPHY **/
$cap-font-weight-lighter: lighter;
$cap-font-weight-light: 300;
$cap-font-weight-normal: 400;
$cap-font-weight-medium: 500;
$cap-font-weight-semi: 700;
$cap-font-weight-bold: 900;
$cap-font-weight-bolder: bolder;

$cap-font-family-sans-serif: Lato, "Helvetica Neue", Arial, sans-serif;
$cap-headings-font-weight: $cap-font-weight-bold;

/** GLOBAL **/
$cap-border-radius: 3px;
$cap-border-radius-sm: 3px;
$cap-border-radius-lg: 4px;

$cap-box-shadow-dark-sm: 0 0.125rem 0.25rem rgba($cap-black, 0.125) !default;
$cap-box-shadow-dark: 0 0.5rem 1rem rgba($cap-black, 0.25) !default;
$cap-box-shadow-dark-lg: 0 1rem 3rem rgba($cap-black, 0.3) !default;
$cap-box-shadow-up: 0 0 0.75rem rgba($cap-black, 0.3);

/** FORMS **/
$cap-caret-width: 0.3em;
$cap-caret-vertical-align: $cap-caret-width * 0.5;
$cap-caret-spacing: $cap-caret-width * 2;

$cap-input-bg: #ecf0f5;
$cap-input-border-color: $cap-input-bg;
$cap-input-focus-bg: $cap-input-bg;
$cap-input-box-shadow: none;
$cap-input-focus-box-shadow: none;
$cap-input-font-weight: $cap-font-weight-normal;

$cap-input-border-radius: 0 0 $cap-border-radius $cap-border-radius;
$cap-input-border-radius-lg: 0 0 $cap-border-radius $cap-border-radius;
$cap-input-border-radius-sm: 0 0 $cap-border-radius $cap-border-radius;

/** GRID **/
$cap-grid-gutter-width: 1rem;

$cap-spacer: 1rem !default;

/** COMPONENTS BS **/
$cap-carousel-control-color: $cap-gray-500;
$cap-carousel-indicator-active-bg: $cap-gray-500;
$cap-carousel-caption-color: $cap-gray-800;

/** COMPONENTS CAP **/
$cap-app-min-height: 100vh;
$cap-app-max-width: 100vw;

$cap-sidebar-z-index: 1021;
$cap-loading-z-index: 1100;

$cap-companies-teaser-list-height: 100px;
$cap-companies-teaser-img-width: 8rem;
$cap-companies-teaser-img-height: 4rem;

$cap-form-floating-label-size: 1.25rem;

$cap-user-registration-icon-size: 20px;

$comparison-toggle-height: 4rem;

$mdi-sizes: 14 16 18 20 24 32 36 40 48 64 128;

$cap-circle-size-xxs: 1.4rem;
$cap-circle-size-xs: 1.6rem;
$cap-circle-size-sm: 2rem;
$cap-circle-size-md: 3rem;
$cap-circle-size-lg: 4rem;
$cap-circle-size-xl: 5rem;
$cap-circle-size-xxl: 7.5rem;
$cap-circle-size-xxxl: 9rem;
$cap-circle-size-xxxxl: 12.5rem;

$ci-form-group-height-sm: 2.5rem;
$ci-form-group-height-md: 3rem;
$ci-form-group-height-lg: 4rem;

$ci-form-label-floating-font-size-sm: 0;
$ci-form-label-floating-font-size-lg: 1rem * 0.75;
$ci-form-label-floating-font-size-md: 1rem * 0.625;

$ci-form-label-floating-height-sm: 0;
$ci-form-label-floating-height-md: 1.125rem;
$ci-form-label-floating-height-lg: 1.5rem;
$ci-form-label-font-weight: $cap-font-weight-normal;
$ci-form-label-floating-font-weight: $cap-font-weight-bold;

$ci-form-input-font-size-sm: 1rem * 0.875;
$ci-form-input-font-size-md: 1rem * 0.875;
$ci-form-input-font-size-lg: 1rem;

$ci-form-hint-font-size: 0.75rem;
$ci-form-hint-line-height: 1.125rem;
$ci-form-hint-padding: 0.25rem 0.5rem;

$ci-form-border-radius: 3px;
$ci-form-border-bottom-size: 2px;
$ci-form-full-border: 1px solid $cap-gray-200;
$ci-form-disabled-opacity: 0.5;

$ci-form-icon-width: 2.5rem;
$ci-form-font-size: 1.25rem;

$ci-action-button-size-sm: 2rem;
$ci-action-button-size: 2.5rem;
$ci-action-button-size-lg: 3rem;

$cap-layout-padding-mobile: 0;
$cap-layout-padding-tablet: 0;
$cap-layout-padding-desktop: $cap-spacer * 1.5;

$cap-content-element-padding-mobile: $cap-spacer;
$cap-content-element-padding-tablet: $cap-spacer * 1.5;
$cap-content-element-padding-desktop: $cap-spacer * 1.5;

$cap-content-header-sticky-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

$ci-button-notification-color: $cap-pink-600;
$ci-button-notification-border-size: 3px;
$ci-button-notification-size-sm: 1.1rem;
$ci-button-notification-size-lg: 1.1rem;
$ci-button-fab-multiplier: 1.25;
$ci-button-xs-size: 2rem;
$ci-button-sm-size: 2.5rem;
$ci-button-md-size: 3rem;
$ci-button-lg-size: 4rem;

$ci-header-background-color: $cap-blue-900;
$ci-header-font-color: white;
$ci-header-height: 3.5rem;
$ci-notice-bar-height: 24px;
$ci-notice-bar-height-sm: 42px;

$ci-max-page-width: 1920px;
$ci-footer-logo-height: 56px;
$ci-footer-background-color: $cap-blue-900;
$ci-footer-background-highlight-color: $cap-secondary;
$ci-full-page-height: calc(100vh - #{$ci-header-height});

$ci-content-padding-mobile: 1rem;
$ci-content-padding-tablet: 1.5rem;
$ci-content-padding-desktop: 1.5rem;
