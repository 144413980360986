.category-card {
  height: 11rem;
  white-space: nowrap;

  ul {
    padding: 0;
  }

  li {
    list-style: none;
  }

  .topcategories {
    height: 70px;
  }

  /* SubMenu */
  li ul {
    position: absolute;
    top: 40px;
    left: 9px;
    right: 0;
    overflow: scroll;
  }

  .category {
    border-top: 2px $cap-primary solid;

    &.child {
      border-top: none;
    }

    a {
      &:hover {
        text-decoration: none;
        color: $cap-primary;
      }
    }
  }

  .inactive-category {
    border-top: 2px $cap-white solid;

    a {
      border-bottom: 1px $cap-gray-200 solid;
      color: $cap-gray-500;
      font-weight: $font-weight-normal;
    }

    &.child {
      border: none;

      a {
        color: $cap-gray-900;
        border-bottom: none;
      }
    }
  }
}

.theme-text-highlight {
  position: absolute;
  background: linear-gradient(to bottom, $cap-primary 25%, #39a991 75%);
  width: 3000px;
  top: -250px;
  right: -2475px;
  bottom: -900px;
  left: 50px;
  padding-left: 30px;
  border-radius: 50%;

  @include media-breakpoint-down(sm) {
    top: 300px;
  }
}

.custom-margins {
  p {
    margin-bottom: 0;
  }

  .ci-product-tile {
    margin-bottom: 1rem;

    @include media-breakpoint-down(sm) {
      margin-bottom: 0.5rem;
    }
  }
}

.theme-text-second-highlight {
  position: absolute;
  background: rgba($primary, 0.2);
  box-shadow: 0 0 0 30px rgba($primary, 0.2);
  border-radius: 50%;
  width: 3000px;
  top: -600px;
  right: -3000px;
  bottom: -2500px;
  left: -73px;
  padding-left: 30px;

  @include media-breakpoint-down(sm) {
    left: 0;
  }
}

.theme-description {
  h1 {
    font-size: 1.563rem;
    margin-bottom: 0.25rem;
  }

  h2 {
    font-size: 1.375rem;
  }

  h3 {
    font-size: 1.25rem;
  }
}
