@use "sass:color"; @import "@frontend/vars.scss";.ci-header {
  background: $ci-header-background-color;
  height: $ci-header-height;

  // position: relative;
  z-index: 1040;
  position: fixed;
  top: 0;
  width: 100%;

  &__container {
    max-width: $ci-max-page-width;
    margin: 0 auto;
    height: $ci-header-height;
  }

  &__logo {
    transform: translateY(1px);
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &__nav {
    &-child {
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background: rgba(255, 255, 255, 0.2);
        }
      }

      .title,
      .description {
        color: white;
      }

      &.active {
        .title,
        .description {
          color: $primary;
        }
      }
    }

    &-item {
      &__dropdown {
        .nav-chapter {
          color: white;
          border-bottom: 1px solid var(--global-color-gray-600);
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__nav {
      &-primary,
      &-list,
      &-item {
        height: $ci-header-height;
      }

      &-item {
        &.hover {
          .ci-header__nav-item__dropdown {
            max-height: 50vh;
            transition: 0.2s all ease;
          }
        }

        &__dropdown {
          position: absolute;
          top: $ci-header-height;
          background: $ci-header-background-color;
          transition: 0.2s all ease;
          width: 100%;
          left: 0;
          max-height: 0;
          overflow: hidden;

          &.routing {
            pointer-events: none;
          }

          &__wrapper {
            max-width: $ci-max-page-width;
            margin: 0 auto;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    &__nav {
      &-primary {
        position: fixed;
        height: $ci-full-page-height;
        top: $ci-header-height;
        background: $ci-header-background-color;
        width: 100%;
        left: 0;
        display: flex;
        flex-direction: column;
        max-height: 0;
        overflow: hidden;

        &.open {
          max-height: calc(100% - 3rem);
          max-height: stretch;
          height: calc(100% - 3rem);
          box-shadow: 0 3rem 0 0 rgba(42, 46, 53, 0.75);

          ul {
            max-height: calc(100% - 3rem);
            max-height: stretch;
            height: calc(100% - 3rem);

            &.signed-in {
              max-height: 100%;

              .scroll-shadow.bottom {
                bottom: 0;
              }
            }
          }

          .scroll-shadow {
            display: block;
          }
        }

        ul {
          overflow: auto;
          position: relative;
          max-height: 0;
        }

        .scroll-shadow {
          display: none;
          height: 4rem;
          position: fixed;
          pointer-events: none;
          left: 0;
          width: 100%;
          z-index: 2;

          &.top {
            top: $ci-header-height;
            background: linear-gradient($ci-header-background-color, rgba(0, 0, 0, 0));
          }

          &.bottom {
            bottom: 3rem;
            background: linear-gradient(to top, $ci-header-background-color, rgba(0, 0, 0, 0));
          }
        }

        > * {
          position: relative;
          z-index: 1;
        }

        .ci-header__nav-item {
          width: 100%;
          flex-direction: column;
          height: auto;
          position: relative;

          > a:not(.drop),
          > button:not(.drop) {
            width: 100%;
            font-size: 1.75rem;
            font-weight: $font-weight-bold;
            height: 60px;
            z-index: 1;

            .ci-btn__content {
              justify-content: space-between;
            }
          }

          button.drop {
            position: absolute !important;
            top: 0;
            right: 0;
            z-index: 2;
            height: 60px;
            width: 60px;
          }

          &__dropdown {
            transition: 0.2s all ease;
            width: 100%;
            left: 0;
            max-height: 0;
            overflow: hidden;

            .ci-header__main-nav__child {
              white-space: pre-wrap;
            }
          }

          &.open {
            .ci-header__nav-item__dropdown {
              max-height: 200vh;
              transition: 0.2s all ease;
            }
          }
        }
      }
    }
  }
}
