@use "sass:color"; @import "@frontend/vars.scss";
.ci-dropdown {
  .toggle {
    z-index: 9 !important;
    cursor: pointer !important;
    height: 100%;
  }

  .ci-dropdown-content {
    z-index: 100 !important;
    position: absolute;
    right: 0;
    white-space: nowrap;
  }
}

.ci-card {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: $border-radius;
}
