@use "sass:color";

@mixin button-ghost-variant(
  $background,
  $border,
  $hover-background: color.adjust($background, $lightness: -7.5%),
  $hover-border: color.adjust($border, $lightness: -10%),
  $active-background: color.adjust($background, $lightness: -10%),
  $active-border: color.adjust($border, $lightness: -12.5%)
) {
  color: $background;
  background-color: transparent;
  background-image: none;
  border-color: transparent;

  &:hover {
    @include gradient-bg($background);

    color: color-yiq($background);
    border-color: $background;
  }

  &:focus,
  &.focus {
    @if $enable-shadows {
      box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($border, 0.5);
    } @else {
      box-shadow: 0 0 0 $btn-focus-width rgba($border, 0.5);
    }
  }

  &:not(:disabled, .disabled):active,
  &:not(:disabled, .disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;

    @if $enable-gradients {
      background-image: none;
    }

    border-color: $active-border;

    &:focus {
      @if $enable-shadows {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($border, 0.5);
      } @else {
        box-shadow: 0 0 0 $btn-focus-width rgba($border, 0.5);
      }
    }
  }
}
