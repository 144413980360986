.fixed-bottom.right {
  right: 0;
  left: auto;
  z-index: 1020;
}

.hidden-overflow-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
body {
  .container-mw {
    max-width: $ci-max-page-width;
    margin: 0 auto;
    width: 100%;
  }
}

.text-container-handheld {
  @include media-breakpoint-only(md) {
    padding-left: $spacer * 1.5;
    padding-right: $spacer * 1.5;
  }

  @include media-breakpoint-down(sm) {
    padding-left: $spacer;
    padding-right: $spacer;
  }
}

.no-gutters-xs {
  @include media-breakpoint-only(xs) {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.no-gutters-sm {
  @include media-breakpoint-only(sm) {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.no-gutters-handheld {
  @include media-breakpoint-down(md) {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  @include media-breakpoint-only(md) {
    > .col-6:nth-child(even),
    > .col-sm-6:nth-child(even),
    > .col-md-6:nth-child(even) {
      padding-left: $grid-gutter-width * 0.5;
    }
    > .col-6:nth-child(odd),
    > .col-sm-6:nth-child(odd),
    > .col-md-6:nth-child(odd) {
      padding-right: $grid-gutter-width * 0.5;
    }
  }
}

.bg-transparent {
  background: transparent !important;
}
