$cap-sidebar-size-collapsed-touch: 2.5rem;
$cap-sidebar-size-expanded-desktop: 16.41625rem;
$cap-sidebar-transition: 0.3s all ease;
$cap-logo-header-height: 4.5rem;

.sign-up-page {
  #confirm-email {
    .registration-headline {
      font-size: 2rem;

      @include media-breakpoint-up(md) {
        font-size: 2.5rem;
      }
    }
    .text-wrap {
      font-size: $font-size-lg;
    }
  }
}

#modal-registration,
#modal-registration-soft-gating {
  #close-button {
    z-index: 1;
    color: white !important;

    @include media-breakpoint-down(lg) {
      color: $cap-gray-600 !important;
    }
  }

  #info-col {
    @include media-breakpoint-up(lg) {
      border-radius: 0 0.3rem 0.3rem 0;
    }

    .information-image {
      height: 13rem;
    }

    .information-headline {
      font-size: $h5-font-size;
    }
  }

  .honeypot-input {
    border: none;
    padding: 0;
    height: 1px;
    width: 1px;
    position: absolute !important;
    overflow: hidden;
  }
}
