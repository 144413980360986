nav .page-link {
  height: 2rem;
}

@media print {
  .print-page-break-before {
    page-break-before: always;
  }
}

.page-item {
  margin: 0 0.1rem;
}

.page-item .page-link {
  border-radius: $border-radius;
  background: transparent;
  font-weight: $font-weight-bold;
  line-height: 2rem;
  padding: 0 0.75rem;

  @include media-breakpoint-down(sm) {
    padding: 0 0.5rem;
    &.mdi {
      padding: 0 0.25rem;
    }
  }
}

.page-item:not(.active, .disabled) .page-link {
  color: $body-color;
}

.page-item.disabled .page-link {
  background: transparent;
}

.page-item:not(.disabled) .page-link {
  cursor: pointer;
}

.page-item.material {
  .page-link {
    @extend .mdi;
  }
  &.first .page-link:before {
    content: "\F0600";
  }
  &.prev .page-link:before {
    content: "\F0141";
  }
  &.next .page-link:before {
    content: "\F0142";
  }
  &.last .page-link:before {
    content: "\F0601";
  }
}
