@use "sass:color"; @import "@frontend/vars.scss";
#errorpage {
  @include media-breakpoint-down(sm) {
    svg {
      max-width: 80%;
    }
  }

  @include media-breakpoint-only(md) {
    padding-left: 3rem;
    padding-right: 3rem;

    svg {
      max-width: 90%;
    }
  }

  @include media-breakpoint-down(md) {
    min-height: calc(100vh - (#{$ci-header-height}));
  }

  @include media-breakpoint-up(lg) {
    min-height: calc(100vh - (#{$cap-layout-padding-desktop} * 2));
    padding-left: 3rem;
    padding-right: 3rem;

    svg {
      max-width: 100%;
    }
  }

  @include media-breakpoint-up(xl) {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .errorpage {
    &__container {
      max-width: 950px;
    }
    &__description {
      @include media-breakpoint-up(lg) {
        font-size: $font-size-lg;
      }
    }
  }
}
