@mixin button-outline-variant(
  $background,
  $border,
  $initial-background: transparent,
  $initial-border: transparent,
  $active-background: $background,
  $active-border: $border
) {
  color: $background;
  background-color: $initial-background;
  border-color: $border;
  background-image: none;

  &:hover {
    color: color-yiq($active-background);
    background-color: $active-background;
    border-color: $active-background;
  }

  &:focus,
  &.focus {
    @if $enable-shadows {
      box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($border, 0.5);
    } @else {
      box-shadow: none;
    }
  }

  &:not(:disabled, .disabled):active,
  &:not(:disabled, .disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    border-color: $active-background;

    @if $enable-gradients {
      background-image: none;
    }

    &:focus {
      @if $enable-shadows {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($border, 0.5);
      } @else {
        box-shadow: none;
      }
    }
  }
}
