.flex-center {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
}

.filled-vp {
  width: 100%;
  height: 100%;
}

.vh-50 {
  height: 50vh !important;
}

.max-vh-50 {
  max-height: 50vh !important;
}

.tooltip-xl .tooltip-inner {
  width: 30rem;
  max-width: 90vw;
}

.tooltip.text-left .tooltip-inner {
  text-align: left;
}

.tooltip.white {
  .tooltip-inner {
    background: white;
    padding: 1rem;
    opacity: 1;
    color: $body-color;
    box-shadow: 0 3px 8px rgba(64, 67, 78, 0.15);
    border: 1px solid $cap-gray-200;
  }
  .arrow:before {
    display: none;
  }
}

.hidden-block {
  visibility: hidden;
}

@include media-breakpoint-down(md) {
  .hidden-block-handheld {
    visibility: hidden;
  }
}
