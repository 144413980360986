@use "sass:color"; @import "@frontend/vars.scss";
.ci-gating {
  position: relative;

  &.gated {
    overflow: hidden;

    > .ci-gating-teaser {
      position: absolute;
      z-index: 1;
    }
  }

  &.ungated {
    > .ci-gating-banner {
      display: none;
    }
    > .ci-gating-teaser {
      position: relative;
      z-index: 1;
    }
  }

  .ci-gating-teaser {
    position: relative;
  }

  .ci-gating-banner {
    position: relative;
    z-index: 2;

    &__fade {
      background: linear-gradient(to top, rgba(#fff, 1) 0%, rgba(#fff, 0) 100%);
    }
    &__blocker {
      background: white;
    }
  }
}
