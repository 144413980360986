@use "sass:color"; @import "@frontend/vars.scss";
.ci-layout {
  min-height: $cap-app-min-height;
  max-width: $cap-app-max-width;

  .below-header {
    z-index: 5 !important;
  }
  .vue-portal-target:has(> .ease-out-section) {
    transform: translateY(0);
    transition: all ease-in 0.3s;
    &.scroll-top {
      top: 0 !important;
      transform: translateY(calc((100% + 3.5rem) * -1));
      transition: all cubic-bezier(0.98, 0.25, 0.98, 0.25) 0.5s;
    }
  }
}
