.article-details {
  &__list {
    padding: 0;

    @media screen and (max-width: 1300px) {
      padding: 0 0.5rem;
    }

    @include media-breakpoint-down(md) {
      padding: 0 1rem;
      margin: 0 0 1rem;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 1rem;
    }

    .text-container-handheld {
      font-size: 1.25rem;

      @include media-breakpoint-down(md) {
        padding: 0;
      }
    }
  }

  &__related {
    @include media-breakpoint-down(sm) {
      padding: 0 1rem;
      margin: 0 0 1rem;

      .related-articles__header {
        padding: 0 !important;
        margin: 0.5rem 0 !important;
      }
    }
  }

  .text-container-handheld {
    font-size: 1.25rem;

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  &__sidebar {
    flex-grow: 1;
    flex-basis: 300px;
    align-self: flex-start;
    position: sticky;
    top: calc(#{$ci-header-height} + 1.5rem);

    &-inner {
      padding: 0 0 0 0.5rem;

      @include media-breakpoint-down(md) {
        padding: 0 1.5rem;
      }

      @include media-breakpoint-down(sm) {
        padding: 0 1rem 0.5rem;
      }
    }

    .ci-content-margin {
      @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;

        &.ci-product-tile {
          margin-bottom: 0.5rem;
        }
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 1rem;
      }
    }

    &-item {
      &__inlay {
        display: flex;
        align-items: center;

        .img {
          margin-right: 1.5rem;
        }

        @include media-breakpoint-only(xl) {
          flex-direction: column;
          text-align: center;

          .img {
            margin-right: 0;
            margin-bottom: 1rem;
          }
        }
      }

      &.article-details__company {
        .img {
          width: 140px;
          min-height: 5rem;
        }
      }
    }

    .article-details__outlet {
      .img {
        width: 100%;
        min-height: 5rem;
      }
    }

    .article-details__products {
      .product-tile {
        box-shadow: none !important;
        border: none !important;
      }
    }
  }
  &__video {
    position: relative;
    padding-bottom: 56.25%; // 16:9
    padding-top: 0;
    height: 0;
    overflow: hidden;
    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &__image {
    overflow: hidden;
    padding-bottom: 50%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  &__company {
    .company-logo {
      width: 8rem;
    }
  }

  &__social_event {
    margin-bottom: 1.5rem;
  }

  &__teaser {
    font-size: 1.125rem;
  }
  &__text {
    img,
    video,
    iframe {
      max-width: 100%;
    }
  }
}
