@use "sass:color";

.font-weight-semi {
  font-weight: $font-weight-semi !important;
}

.text-xs {
  font-size: $font-size-xs;
}

.text-sm {
  font-size: $font-size-sm;
}

.text-md {
  font-size: $font-size-md;
}

.text-lg {
  font-size: $font-size-lg;
}

.text-xxl {
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.text-degraded {
  color: rgba(0, 0, 0, 0.75);
}

.line-height-md {
  line-height: $line-height-md;
}

.chapter {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 1em;
  margin-bottom: 0.75em;
  padding-bottom: 0.75em;
  position: relative;

  &:after {
    content: "\00a0";
  }
}

.text-preserve {
  white-space: pre;
}

a.text-body {
  @include hover-focus {
    color: color.adjust($body-color, $lightness: calc(-1 * $emphasized-link-hover-darken-percentage)) !important;
  }
}

.text-primary-700 {
  color: $cap-primary-700;
}
