@use "sass:color"; @import "@frontend/vars.scss";
@mixin size($name) {
  &--size-#{$name} {
    --button-size: var(--button-#{$name}-size);
    --button-font-size: var(--button-#{$name}-font-size);
    --button-icon-size: var(--button-#{$name}-font-size);

    .ci-btn__icon {
      &.icon-only.mdi:before {
        --button-icon-size: var(--button-#{$name}-font-size-icon-single);
      }
      + .ci-btn__notification {
        --button-notification-y-offset: var(--button-#{$name}-notification-y-offset);
        --button-notification-x-offset: var(--button-#{$name}-notification-x-offset);
      }
    }
  }
}

@mixin variant($name) {
  &[class*="#{$name}"] {
    --button-background-color: var(--button-#{$name}-background-color);
    --button-text-color: var(--button-#{$name}-text-color);
    --button-border-color: var(--button-#{$name}-border-color);
    --button-border-width: 0;
    --button-ripple-color: var(--button-#{$name}-ripple-color);

    @if $name == "outline" or $name == "ghost" {
      --button-notification-border: white;
      --button-border-width: thin;
    }

    &.ci-btn--disabled {
      --button-notification-border: var(--button-background-color);
      --button-border-color: var(--button-#{$name}-border-color--hover);

      @if $name == "outline" {
        --button-background-color: var(--button-#{$name}-background-color);
        --button-text-color: var(--button-#{$name}-text-color);
      } @else {
        --button-background-color: var(--button-#{$name}-background-color--hover);
        --button-text-color: var(--button-#{$name}-text-color--hover);
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover:not([disabled="disabled"]) {
        --button-notification-border: var(--button-background-color);
        --button-background-color: var(--button-#{$name}-background-color--hover);
        --button-text-color: var(--button-#{$name}-text-color--hover);
        --button-border-color: var(--button-#{$name}-border-color--hover);
      }

      &:focus-visible:not([disabled="disabled"]) {
        --button-background-color: var(--button-#{$name}-background-color--hover);
        --button-notification-border: var(--button-background-hover);
        --button-text-color: var(--button-#{$name}-text-color--hover);
        --button-border-color: var(--button-#{$name}-border-color--hover);
      }
    }

    &.ci-btn--active:not([disabled="disabled"]) {
      --button-notification-border: var(--button-background-color);
      --button-background-color: var(--button-#{$name}-background-color--active);
      --button-text-color: var(--button-#{$name}-text-color--active);
      --button-border-color: var(--button-#{$name}-border-color--active);
    }
  }
}

@mixin color($name) {
  // Can only be used for real colors, the gray button has to many exceptions
  &[class*="#{$name}"] {
    // Warning color requires darker tone to keep contrast with background at higher level
    @if $name == "warning" {
      --button-filled-background-color: var(--global-color-#{$name}-700);
      --button-filled-background-color--hover: var(--global-color-#{$name}-800);
      --button-filled-text-color: white;
      --button-filled-text-color--hover: white;
      --button-filled-border-color: var(--global-color-#{$name}-700);
      --button-filled-border-color--hover: var(--global-color-#{$name}-800);
      --button-filled-ripple-color: var(--global-color-#{$name}-900);
    } @else {
      --button-filled-background-color: var(--global-color-#{$name}-500);
      --button-filled-background-color--hover: var(--global-color-#{$name}-600);
      --button-filled-text-color: white;
      --button-filled-text-color--hover: white;
      --button-filled-border-color: var(--global-color-#{$name}-500);
      --button-filled-border-color--hover: var(--global-color-#{$name}-600);
      --button-filled-ripple-color: var(--global-color-#{$name}-900);
    }
  }
}

.ci-btn {
  // All default values are referring to the SM size

  --button-xs-size: #{$ci-button-xs-size};
  --button-xs-font-size: 0.875rem;
  --button-xs-font-size-icon-single: 1.125rem;
  --button-xs-notification-y-offset: 0.25rem;
  --button-xs-notification-x-offset: 0.25rem;

  --button-sm-size: #{$ci-button-sm-size};
  --button-sm-font-size: 1rem;
  --button-sm-font-size-icon-single: 1.375rem;
  --button-sm-notification-y-offset: 0;
  --button-sm-notification-x-offset: -0.5rem;

  --button-md-size: #{$ci-button-md-size};
  --button-md-font-size: 1.125rem;
  --button-md-font-size-icon-single: 1.75rem;
  --button-md-notification-y-offset: 0.6rem;
  --button-md-notification-x-offset: 0.5rem;

  --button-lg-size: #{$ci-button-lg-size};
  --button-lg-font-size: 1.125rem;
  --button-lg-font-size-icon-single: 1.75rem;
  --button-lg-notification-y-offset: 0.6rem;
  --button-lg-notification-x-offset: 0.5rem;

  --button-notification-color: var(--global-color-tertiary-600);
  --button-notification-y-offset: var(--button-sm-notification-y-offset);
  --button-notification-x-offset: var(--button-sm-notification-x-offset);
  --button-notification-border-color: var(--button-background-color);
  --button-background-color: transparent;
  --button-text-color: var(--global-color-gray-600);
  --button-border-color: transparent;
  --button-size: var(--button-sm-size);
  --button-font-size: var(--button-sm-font-size);
  --button-icon-size: var(--button-sm-font-size-icon-single);
  --button-ripple-color: rgba(0, 0, 0, 0.2);

  --button-filled-background-color: transparent;
  --button-filled-background-color--hover: transparent;
  --button-filled-background-color--active: transparent;
  --button-filled-text-color: var(--button-text-color);
  --button-filled-text-color--hover: var(--button-text-color);
  --button-filled-text-color--active: var(--button-text-color);
  --button-filled-border-color: transparent;
  --button-filled-border-color--hover: transparent;
  --button-filled-border-color--active: transparent;
  --button-filled-ripple-color: transparent;

  --button-outline-background-color: transparent;
  --button-outline-background-color--hover: var(--button-filled-background-color--hover);
  --button-outline-background-color--active: var(--button-filled-background-color--active);
  --button-outline-text-color: var(--button-filled-background-color);
  --button-outline-text-color--hover: var(--button-filled-text-color--hover);
  --button-outline-text-color--active: var(--button-filled-text-color--active);
  --button-outline-border-color: var(--button-filled-background-color);
  --button-outline-border-color--hover: var(--button-filled-border-color--hover);
  --button-outline-border-color--active: var(--button-filled-border-color--active);
  --button-outline-ripple-color: var(--button-filled-ripple-color);

  --button-ghost-background-color: transparent;
  --button-ghost-background-color--hover: var(--button-filled-background-color--hover);
  --button-ghost-background-color--active: var(--button-filled-background-color--active);
  --button-ghost-text-color: var(--button-filled-background-color);
  --button-ghost-text-color--hover: var(--button-filled-text-color--hover);
  --button-ghost-text-color--active: var(--button-filled-text-color--active);
  --button-ghost-border-color: transparent;
  --button-ghost-border-color--hover: var(--button-filled-border-color--hover);
  --button-ghost-border-color--active: var(--button-filled-border-color--active);
  --button-ghost-ripple-color: var(--button-filled-ripple-color);

  font-family: $font-family-sans-serif;
  font-weight: $font-weight-semi;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: var(--button-border-width) solid var(--button-border-color);

  // Default style
  color: var(--button-text-color);
  background-color: var(--button-background-color);
  border-radius: $border-radius;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  height: var(--button-size);
  min-width: var(--button-size);
  font-size: var(--button-font-size);

  // STATES

  @media (hover: hover) and (pointer: fine) {
    &:hover :deep(.ripple) {
      display: block;
    }
  }

  &:focus,
  &.focus {
    outline: 0;
  }

  &.disabled,
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &--gated {
    opacity: 1 !important;
  }

  &--icon-only {
    padding: 0;
  }

  &--square,
  &--square :deep(.ripple-wrapper) {
    border-radius: 0 !important;
  }

  &--fab {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: $box-shadow;
    border-radius: 50%;
    width: calc(var(--button-size) * #{$ci-button-fab-multiplier});
    height: calc(var(--button-size) * #{$ci-button-fab-multiplier});

    .ci-btn__label {
      display: none !important;
    }

    &:hover {
      box-shadow: $box-shadow-lg;
    }

    :deep(.ripple-wrapper) {
      border-radius: 50%;
    }
  }

  // CONTENT

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    width: 100%;
  }

  &__icon {
    &:not([class*="px"]).mdi:before {
      font-size: var(--button-icon-size);
    }

    + .ci-btn__notification {
      position: absolute;
      height: $ci-button-notification-size-sm;
      min-width: $ci-button-notification-size-sm;
      padding: 0 0.25rem;
      line-height: calc(#{$ci-button-notification-size-sm} + 0.1rem);
      top: var(--button-notification-y-offset);
      right: var(--button-notification-x-offset);

      &--bubble {
        top: 2px;
        right: -4px;
        width: 11px;
        height: 11px;
        border: 2px solid $cap-white;
      }
    }
  }

  &__label {
    + .ci-btn__notification {
      height: $ci-button-notification-size-lg;
      min-width: $ci-button-notification-size-lg;
      padding: 0 0.25rem;
      line-height: $ci-button-notification-size-lg;
      margin-left: 0.5rem;
    }
  }

  &--number-notification {
    &:hover {
      .ci-btn__notification--bubble {
        // Not using CSS var because it contains alpha parameter which destroys the effect
        border-color: #f0f0f2;
      }
    }
  }

  &__notification {
    background: $ci-button-notification-color;
    border-radius: calc(#{$ci-button-notification-size-sm} / 2);
    font-size: 0.75rem;
    font-weight: $font-weight-bold;

    &--bubble {
      .mdi-history + & {
        top: 5px;
        right: 5px;
      }
    }
  }

  :deep(.ripple) {
    display: none;
    background: var(--button-ripple-color);
  }

  // SIZES
  @include size("xs");
  @include size("sm");
  @include size("md");
  @include size("lg");

  // VARIANTS

  @include variant("filled");
  @include variant("outline");
  @include variant("ghost");

  // COLORS

  @include color("primary");
  @include color("secondary");
  @include color("tertiary");
  @include color("danger");
  @include color("warning");

  &[class*="light"] {
    --button-filled-background-color: var(--global-color-gray-100);
    --button-filled-background-color--hover: var(--global-color-gray-200);
    --button-filled-background-color--active: var(--global-color-gray-300);
    --button-filled-text-color: var(--global-color-black);
    --button-filled-text-color--hover: var(--global-color-black);
    --button-filled-text-color--active: var(--global-color-black);
    --button-filled-border-color: transparent;
    --button-filled-border-color--hover: transparent;
    --button-filled-border-color--active: transparent;
    --button-filled-ripple-color: var(--global-color-gray-300);

    --button-outline-background-color: transparent;
    --button-outline-background-color--hover: var(--global-color-gray-100);
    --button-outline-background-color--active: var(--global-color-gray-200);
    --button-outline-text-color: var(--global-color-gray-600);
    --button-outline-text-color--hover: var(--global-color-gray-600);
    --button-outline-text-color--active: var(--global-color-black);
    --button-outline-border-color: var(--global-color-gray-600);
    --button-outline-border-color--hover: var(--button-filled-border-color--hover);
    --button-outline-border-color--active: var(--button-filled-border-color--active);
    --button-outline-ripple-color: var(--global-color-gray-200);

    --button-ghost-background-color: transparent;
    --button-ghost-background-color--hover: rgba(103, 113, 129, 0.1);
    --button-ghost-background-color--active: rgba(103, 113, 129, 0.1);
    --button-ghost-text-color: var(--global-color-gray-600);
    --button-ghost-text-color--hover: var(--global-color-gray-600);
    --button-ghost-text-color--active: var(--global-color-black);
    --button-ghost-border-color: transparent;
    --button-ghost-border-color--hover: var(--button-filled-border-color--hover);
    --button-ghost-border-color--active: var(--button-filled-border-color--active);
    --button-ghost-ripple-color: var(--global-color-gray-200);
  }

  &[class*="dark"] {
    --button-ghost-background-color: transparent;
    --button-ghost-text-color: var(--global-color-gray-800);
  }

  &[class*="inline-share-profile"] {
    width: 100%;
    padding-left: $ci-content-padding-desktop;

    --button-icon-size: 24px;

    .ci-btn__content {
      justify-content: flex-start;
    }

    .ci-btn__icon {
      margin-right: 16px !important;
    }
  }

  &[class*="navigation"] {
    --button-filled-background-color: transparent;
    --button-filled-background-color--hover: rgba(255, 255, 255, 0.2);
    --button-filled-background-color--active: var(--global-color-primary);
    --button-filled-text-color: var(--global-color-white);
    --button-filled-text-color--hover: var(--global-color-white);
    --button-filled-text-color--active: var(--global-color-white);
    --button-filled-border-color: transparent;
    --button-filled-border-color--hover: transparent;
    --button-filled-border-color--active: transparent;
    --button-filled-ripple-color: var(--global-color-gray-300);
  }
  &[class*="navigation-drop"] {
    --button-filled-background-color: transparent;
    --button-filled-background-color--hover: rgba(255, 255, 255, 0.2);
    --button-filled-background-color--active: transparent;
    --button-filled-text-color: var(--global-color-white);
    --button-filled-text-color--hover: var(--global-color-white);
    --button-filled-text-color--active: var(--global-color-white);
    --button-filled-border-color: transparent;
    --button-filled-border-color--hover: transparent;
    --button-filled-border-color--active: transparent;
    --button-filled-ripple-color: var(--global-color-gray-300);
  }

  &[class*="white"] {
    --button-outline-background-color: transparent;
    --button-outline-background-color--hover: white;
    --button-outline-background-color--active: white;
    --button-outline-text-color: white;
    --button-outline-text-color--hover: black;
    --button-outline-text-color--active: black;
    --button-outline-border-color: white;
    --button-outline-border-color--hover: white;
    --button-outline-border-color--active: white;
    --button-outline-ripple-color: var(--global-color-gray-200);
  }

  &[class*="side-menu"] {
    --button-icon-size: 24px;
    --button-size: var(--button-md-size);
    --button-font-size: var(--button-sm-font-size);
    --button-icon-size: 24px;

    width: 100%;
    .ci-btn__content {
      justify-content: flex-start;
    }

    .ci-btn__icon {
      margin-right: 16px !important;
    }
    &.ci-btn--active:not([disabled="disabled"]) {
      --button-text-color: var(--global-color-primary-700);
    }

    @media (hover: hover) {
      &:hover:not([disabled="disabled"]) {
        --button-background-color: var(--global-color-gray-100);
      }
    }
  }
}
