.ais-CurrentRefinements-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.ais-CurrentRefinements-item {
  height: 2.5rem;
  border-radius: 1.25rem;
  background: $body-bg;
  display: flex;
  align-items: center;
}

.ais-CurrentRefinements-label {
  font-weight: $font-weight-semi;
  padding: 0 0.5rem 0 1rem;
}

.ais-CurrentRefinements-category {
  height: 2.5rem;
  border-radius: 1.25rem;
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
  &:hover {
    background: $gray-200;
  }
}

.ais-CurrentRefinements-delete {
  border: none;
  background: transparent;
}
