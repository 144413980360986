@use "sass:color"; @import "@frontend/vars.scss";
#notifications-popup-wrapper {
  .backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .notifications-popup {
    position: absolute;
    right: 0;
    z-index: 3;
    top: calc(#{$ci-header-height} - 4px);
    width: 480px;
    max-height: 0;
    transition: 0.3s all ease;
    scrollbar-width: none;
    overflow: auto;
    overflow-y: hidden;

    @include media-breakpoint-only(md) {
      right: -2.75rem;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .popup-header,
    .popup-footer {
      background: white;
      z-index: 5;
      position: sticky;
    }

    .popup-header {
      top: 0;

      :deep(.nav-item) {
        width: 50%;
        text-align: center;
      }
    }

    .popup-title {
      font-size: $h3-font-size;
      font-weight: $font-weight-bold;
      line-height: normal;
    }

    .popup-footer {
      bottom: 0;
      box-shadow: 0 -0.125rem 0.25rem rgba($cap-black, 0.125);
    }

    .popup-body {
      position: relative;
      z-index: 1;
    }

    :deep(.ci-checkbox--toggle) {
      margin-left: 16px;
    }
  }

  &.open {
    .backdrop {
      display: block;
    }

    .notifications-popup {
      overflow: auto;
      max-height: calc(100vh - #{$ci-header-height} - #{$cap-layout-padding-desktop});
    }
  }

  &:not(.open) {
    .backdrop {
      display: none;
    }

    .notifications-popup {
      overflow: hidden;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}
