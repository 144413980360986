@mixin circle-size($box-size) {
  width: $box-size;
  height: $box-size;
}

.circle {
  --size: var(--global-circle-size-md);

  border-radius: 50%;
  width: var(--size);
  height: var(--size);

  &.xxs,
  &.btn-xxs {
    --size: var(--global-circle-size-xxs);
  }

  &.xs,
  &.btn-xs {
    --size: var(--global-circle-size-xs);
  }

  &.sm,
  &.btn-sm {
    --size: var(--global-circle-size-sm);
  }

  &.lg,
  &.btn-lg {
    --size: var(--global-circle-size-lg);
  }

  &.xl,
  &.btn-xl {
    --size: var(--global-circle-size-xl);
  }

  &.xxl,
  &.btn-xxl {
    --size: var(--global-circle-size-xxl);
  }

  &.xxxl,
  &.btn-xxxl {
    --size: var(--global-circle-size-xxxl);
  }
  &.xxxxl,
  &.btn-xxxxl {
    --size: var(--global-circle-size-xxxxl);
  }
}
