@mixin set-content-padding($direction: false, $desktop: true) {
  @include media-breakpoint-down(sm) {
    @if $direction {
      padding-#{$direction}: $ci-content-padding-mobile;
    } @else {
      padding: $ci-content-padding-mobile;
    }
  }

  @include media-breakpoint-only(md) {
    @if $direction {
      padding-#{$direction}: $ci-content-padding-tablet;
    } @else {
      padding: $ci-content-padding-tablet;
    }
  }

  @if $desktop {
    @include media-breakpoint-up(lg) {
      @if $direction {
        padding-#{$direction}: $ci-content-padding-desktop;
      } @else {
        padding: $ci-content-padding-desktop;
      }
    }
  }
}

.ci-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: $ci-full-page-height;

  @include media-breakpoint-down(md) {
    max-width: 100vw;
  }

  &__body {
    @include media-breakpoint-up(lg) {
      padding: $cap-layout-padding-desktop;
    }

    &__inner {
      flex: 1;
      min-width: 0;
    }
  }
}

.ci-content-header {
  margin: 0;
  background: white;

  @include media-breakpoint-down(sm) {
    margin-bottom: 1rem;
  }

  @include media-breakpoint-only(md) {
    margin-bottom: 1.5rem;
  }

  &__inner {
    max-width: $ci-max-page-width;
    margin: 0 auto;
    width: 100%;
  }

  &.sticky {
    box-shadow: $cap-content-header-sticky-shadow;
    position: sticky;
    top: 0;
    z-index: 10;
  }
}

.ci-content-header__inner,
.ci-content-padding {
  @include set-content-padding;

  &-as-body {
    @include set-content-padding(false, false);
  }
}
.ci-content-padding {
  &-x,
  &-r {
    @include set-content-padding("right");
  }
  &-x,
  &-l {
    @include set-content-padding("left");
  }
  &-y,
  &-t {
    @include set-content-padding("top");
  }
  &-y,
  &-b {
    @include set-content-padding("bottom");
  }

  &-as-body-x,
  &-as-body-r {
    @include set-content-padding("right", false);
  }
  &-as-body-x,
  &-as-body-l {
    @include set-content-padding("left", false);
  }
  &-as-body-y,
  &-as-body-t {
    @include set-content-padding("top", false);
  }
  &-as-body-y,
  &-as-body-b {
    @include set-content-padding("bottom", false);
  }
}

.row.with-gutter-bottom {
  margin-bottom: $cap-grid-gutter-width * -1;

  @include media-breakpoint-down(sm) {
    margin-bottom: $cap-grid-gutter-width * -0.5;
  }

  > [class*="col"] {
    margin-bottom: $cap-grid-gutter-width;

    @include media-breakpoint-down(sm) {
      margin-bottom: $cap-grid-gutter-width * 0.5;
    }
  }
}

.ci-section,
.ci-content-margin {
  @include media-breakpoint-down(sm) {
    margin-bottom: 1rem;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 1.5rem;
  }
}
.ci-section {
  &:last-child {
    margin-bottom: 0;
  }
}
