@use "sass:color"; @import "@frontend/vars.scss";
.tag {
  border-radius: 15px;
  width: max-content;

  &.default {
    background: $cap-gray-100;
    height: 2rem;
    box-sizing: border-box;
    padding: 0.25rem 0.75rem;
    color: $cap-black;
    font-size: 1rem;
    font-weight: $font-weight-semi;
  }

  &.light {
    border: 1px solid $cap-gray-200;
    padding: 0 0.75rem;
    background: none !important;
    color: $cap-gray-700;
    height: 1.5rem;
  }

  &.outline-primary {
    border: 1px solid $cap-primary-500;
    padding: 0 0.75rem;
    background: none !important;
    color: $cap-primary-500;
    height: 2rem;
    &:hover {
      background: $cap-primary-600 !important;
      color: $cap-white;
    }
  }

  &:not(.no-hover) {
    cursor: pointer;

    &:hover {
      background: $cap-gray-200;
    }
  }

  &.selected {
    background: $cap-primary;
    color: $cap-white;

    &:hover {
      background: $cap-primary-600;
    }
  }

  input {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}
