/* Root registration */
:root {
  /** CIRCLES **/

  --global-circle-size-xxs: #{$cap-circle-size-xxs};
  --global-circle-size-xs: #{$cap-circle-size-xs};
  --global-circle-size-sm: #{$cap-circle-size-sm};
  --global-circle-size-md: #{$cap-circle-size-md};
  --global-circle-size-lg: #{$cap-circle-size-lg};
  --global-circle-size-xl: #{$cap-circle-size-xl};
  --global-circle-size-xxl: #{$cap-circle-size-xxl};
  --global-circle-size-xxxl: #{$cap-circle-size-xxxl};
  --global-circle-size-xxxxl: #{$cap-circle-size-xxxxl};
  --global-color-black: #{$cap-black};
  --global-color-white: #{$cap-white};

  // Only add colors that we currently use via css variables!

  @each $color, $value in $theme-colors {
    --global-color-#{$color}: #{$value};
  }

  @each $color, $value in $cap-gray-colors {
    --global-color-gray-#{$color}: #{$value};
  }

  @each $color, $value in $cap-primary-colors {
    --global-color-primary-#{$color}: #{$value};
  }

  @each $color, $value in $cap-blue-colors {
    --global-color-secondary-#{$color}: #{$value};
  }

  @each $color, $value in $cap-pink-colors {
    --global-color-tertiary-#{$color}: #{$value};
  }

  @each $color, $value in $cap-danger-colors {
    --global-color-danger-#{$color}: #{$value};
  }

  @each $color, $value in $cap-yellow-colors {
    --global-color-warning-#{$color}: #{$value};
  }
}
