.user-search-modal {
  height: 90vh;
  max-height: 700px;

  @include media-breakpoint-up(lg) {
    height: 75vh;
  }

  .user-list {
    overflow-y: scroll;
  }

  .user-item {
    cursor: pointer;

    &:hover {
      background-color: $cap-gray-100;
    }
  }

  .divider {
    border-top: $cap-gray-300 solid 1px;
  }

  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  /* our custom checkbox have a margin at the bottom that prevents us from centering them vertically */
  .custom-checkbox {
    margin-bottom: 0;
  }
}
