@media print {
  .print-logo {
    margin-bottom: 3rem;
    height: 3rem;
  }

  .page-break {
    height: 1px;
    background: transparent;
    page-break-before: always;
    clear: both;
  }

  .ci-layout {
    display: block !important;
    padding: 0 !important;
  }

  .ci-content {
    display: block !important;
    width: 100% !important;
    max-width: 100% !important;

    &__body {
      padding: 0 !important;
    }
  }
}
