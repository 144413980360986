@use "sass:color"; @import "@frontend/vars.scss";
.ci-header__nav-secondary {
  &__toggle-tooltip {
    margin-top: 1rem !important;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
