@mixin label-to-top() {
  label {
    top: 0;
    align-items: center;
    font-size: $font-size-xs !important;
    font-weight: $font-weight-semi;
    color: $text-muted;
    border-radius: $border-radius $border-radius 0 0;
  }

  &.sm label {
    height: $cap-form-floating-label-size + 0.5rem !important;
  }

  &.md label {
    height: $cap-form-floating-label-size + 0.5rem !important;
  }

  &.lg label {
    height: $cap-form-floating-label-size + 0.5rem !important;
  }
}

.form-element {
  &.invalid {
    .custom-select,
    .form-control {
      border-color: $danger;
    }
  }

  label {
    white-space: nowrap;

    @include overflow-ellipsis;
  }

  .form-control-icon {
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 0;
    pointer-events: none;
    transform: translateY(-50%);
  }

  .custom-select,
  .form-control {
    border: none;
    border-bottom: 2px solid $input-bg;

    &.invalid {
      border-color: $danger;
    }

    // &.valid {
    //   border-color: $primary;
    // }

    &:focus {
      border-color: $primary;
    }
  }

  .form-control.select {
    margin-top: 30px;
  }

  &.unfocused {
    input {
      &::placeholder {
        color: rgba(0, 0, 0, 0);
        transition: 0.05s all 0s ease;
      }
    }
  }

  &.input:not(.hidden, .file),
  &.select {
    padding-top: $cap-form-floating-label-size;
    position: relative;
    background-color: $input-bg;
    border-radius: $border-radius;

    select {
      background: none !important;
    }

    .select-icon {
      z-index: 2;
      top: 0;
      right: 0;
      height: calc(1.5em + 2rem);
      position: absolute;
      bottom: 0;
      padding: 0 0.5rem;
      display: flex;
      align-items: center;
      pointer-events: none;
    }

    label {
      display: flex !important;
      margin: 0;
      position: absolute;
      left: 0;
      z-index: 2;
      pointer-events: none;
      top: 0;
      width: 100%;
      border-radius: $border-radius;
      border: 1px solid transparent;
      transition: 0.1s all ease;
      background: $input-bg;
      color: $text-muted;

      @include overflow-ellipsis;
    }

    &.sm label {
      height: calc(calc(#{$input-height-sm} + #{$cap-form-floating-label-size}) - 2px);
      line-height: calc(calc(#{$input-height-sm} + #{$cap-form-floating-label-size}) - 2px);

      @include font-size($input-font-size-sm);

      padding: 0 $input-padding-x-sm;
    }

    &.md label {
      height: calc(calc(#{$input-height} + #{$cap-form-floating-label-size}) - 2px);
      line-height: calc(calc(#{$input-height} + #{$cap-form-floating-label-size}) - 2px);

      @include font-size($input-font-size);

      padding: 0 $input-padding-x;
    }

    &.lg label {
      height: calc(calc(#{$input-height-lg} + #{$cap-form-floating-label-size}) - 2px);
      line-height: calc(calc(#{$input-height-lg} + #{$cap-form-floating-label-size}) - 2px);

      @include font-size($input-font-size-lg);

      padding: 0 $input-padding-x-lg;
    }

    &.has-icon {
      label,
      input {
        padding-left: 50px;
      }
    }
  }

  &.focused {
    label {
      color: $primary !important;
    }
  }

  &.unfocused-ie {
    @include label-to-top;

    label {
      color: $text-muted !important;
    }
  }

  &.focused,
  &.populated {
    @include label-to-top;
  }

  &.textarea {
    @include label-to-top;
  }
}

.display-inherit {
  display: inherit !important;
}

.form-group {
  .custom-control-label {
    display: flex;
    cursor: pointer;
    align-items: center;
    min-height: 1.5rem;

    &:before,
    &:after {
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }

    .unflexed & {
      display: inherit !important;
    }
  }

  .form-inline {
    .help-block {
      color: $red;
    }
  }
}

.control-label {
  &.required {
    display: flex;

    abbr {
      order: 10;
      margin-left: 0.25rem;
      text-decoration: none;
    }
  }
}

.cap-form-element.custom-checkbox,
.cap-form-element.custom-radio {
  line-height: 1.3em;
  padding-left: 2rem;
  margin-top: 0.5em;

  &.single {
    padding-left: 0;

    .custom-control-label {
      &:before {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
      }

      &:after {
        top: auto;
        left: auto;
      }
    }
  }

  &.single-center {
    .custom-control-label {
      justify-content: center;

      &:after {
        left: 50%;
        top: auto;
        transform: translateX(-50%);
      }
    }
  }
}
