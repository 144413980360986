@use "sass:color"; @import "@frontend/vars.scss";
.dropdown-item {
  cursor: pointer;
  color: $cap-gray-600;
  &:hover {
    &:not(.disabled) {
      color: $cap-black;
      background: $cap-gray-100;
    }
  }
}

.ci-share {
  .share-option:last-of-type .btn {
    margin-bottom: 0;
  }
}
