#companies-teaser-list {
  height: $cap-companies-teaser-list-height;

  .carousel-item {
    height: $cap-companies-teaser-list-height;
  }

  .carousel-caption {
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    padding: 0;
    z-index: 3;
  }
}
