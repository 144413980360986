@use "sass:color"; @import "@frontend/vars.scss";
.suggested-external-content {
  &.skyscraper {
    width: 300px;
    position: sticky;
    .item {
      position: sticky;
    }
  }
  &.sidebar {
    width: 300px;
    position: sticky;
    .item {
      position: sticky;
    }
  }
  &.display {
    width: 100%;
  }
  :deep(.item[id$="_header_slot"]) {
    height: 250px;

    @media (max-width: 1066px) {
      height: 90px;
    }

    @media (max-width: 776px) {
      height: 250px;
    }
  }
}
