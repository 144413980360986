@use "sass:color"; @import "@frontend/vars.scss";
.ci-notifications-list {
  @include media-breakpoint-down(sm) {
    min-height: 65vh;
  }

  .emptystate-buttons-gap {
    gap: 1rem;
  }

  &__edit-button {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: auto;
    }
  }

  .notification-list-item {
    container-type: inline-size;

    &:last-of-type {
      :deep(.notification) {
        border-bottom: none;
      }

      :deep(.notifications-item) {
        border-radius: 0 0 $border-radius $border-radius;

        .notifications-item-wrapper {
          border-bottom: none;
        }
      }
    }
  }
}
