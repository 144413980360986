html {
  scroll-behavior: smooth;
  scroll-padding-top: $ci-header-height;
}

#app {
  @include font-antialiasing;
}

a:focus {
  outline: none !important;
}

body {
  @media print {
    background: white;
  }
}
