// Inspired by version of https://github.com/nullilac/skeleton-screen-css

/* Variables */
$skeleton-element-color: $cap-gray-100;
$skeleton-heights: 1, 2, 3, 4, 5;

.skel-box {
  display: block;
  background-color: $skeleton-element-color;
  width: 100%;
  min-height: 0.5rem;
  border-radius: $border-radius;
  user-select: none;
}

h1.skel-box {
  height: $h1-font-size;
}
h2.skel-box {
  height: $h2-font-size;
}
h3.skel-box {
  height: $h3-font-size;
}
h4.skel-box {
  height: $h4-font-size;
}
h5.skel-box {
  height: $h5-font-size;
}
h6.skel-box {
  height: $h6-font-size;
}

@each $size in $skeleton-heights {
  .skel-height-#{$size} {
    height: $size + rem !important;
  }
  .skel-height-#{$size}.plus-half {
    height: $size + 0.5 + rem !important;
  }
}

.skel-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
