@use "sass:color"; @import "@frontend/vars.scss";
.ci-header__search_dropdown {
  .ci-form--wrapper {
    height: 40px;

    .ci-form--group {
      height: 40px;
    }

    .v-select {
      .vs {
        &__dropdown-menu {
          border-radius: $border-radius;
          top: calc(100% + 6px);
          padding: 0.5rem 0;

          @include media-breakpoint-up(lg) {
            max-height: 700px;
          }

          .result-decoration {
            width: 2rem;
            flex-shrink: 0;
          }
          .result-chapter {
            border-top: 1px solid var(--global-color-gray-200);
          }
        }
        &__search {
          padding-top: 2px !important;
          &::placeholder {
            opacity: 0.3;
          }
        }
        &__dropdown-option {
          white-space: normal;
        }
      }
    }
  }
}
