#klaro {
  .klaro {
    .cookie-notice {
      position: fixed;
      background: rgba(0, 0, 0, 0.6);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 3000;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: initial;

      .cn-body {
        width: 460px;
        max-width: initial;
        position: static;

        @extend .shadow;
        @extend .card;

        padding: 1.5rem;
        margin: 1rem;

        .cn-body {
          padding: 0;
        }

        strong {
          color: $body-color;
        }

        div:not(.cn-ok) {
          margin-top: 0;
          color: $body-color;
          font-size: 0.9rem;
        }

        .cn-buttons {
          @media screen and (max-width: 384px) {
            button {
              &.cm-btn {
                width: 100%;
              }
            }
          }
        }

        .cn-ok {
          @extend .d-flex;
          @extend .flex-column;
          .cn-buttons {
            width: 100%;

            @extend .d-flex;
            @extend .flex-column;

            button {
              display: block;
            }
          }
          .cm-link {
            width: 100%;
          }
        }

        .cm-btn,
        .cm-link {
          @extend .btn;

          margin-bottom: 0.5rem;
          margin-right: 0;
        }

        .cm-btn-danger {
          @extend .btn-outline-medium;

          order: 2;
        }
        .cm-btn-success {
          @extend .btn-lg;
          @extend .btn-primary;

          padding: 1rem;
          order: 1;
        }
        .cm-link {
          order: 3;
          color: $medium;

          @extend .btn-ghost-medium;
        }
      }
    }

    .cookie-modal {
      z-index: 3000;
      .cm-bg {
        background: rgba(0, 0, 0, 0.6);
      }
      .cm-modal {
        @extend .shadow;
        @extend .card;

        h1,
        p {
          color: $body-color;
        }

        a {
          color: $primary;
          &:hover {
            color: $cap-primary-700;
          }
        }

        .cm-header,
        .cm-body,
        .cm-footer {
          padding: 1.5rem;
        }

        .cm-header {
          border-bottom-color: rgba(0, 0, 0, 0.1);
          .cm-modal .hide svg {
            stroke: #828282;
          }

          p {
            font-size: 0.9rem;
          }
        }

        .cm-body {
          li {
            color: $body-color;
          }
          ul.cm-apps li.cm-app p.purposes {
            color: rgba(0, 0, 0, 0.5);
          }

          .cm-app-input:checked + .cm-app-label .slider {
            background: $primary;
          }

          .switch {
            .slider {
              box-shadow: none;
            }
          }
        }

        .cm-footer {
          border-top-color: rgba(0, 0, 0, 0.1);

          .cm-footer-buttons {
            display: flex;
            flex-flow: unset !important;
            justify-content: space-between;

            &:before {
              display: block;
              content: " ";
            }

            .cm-btn {
              @extend .btn;

              margin: 0;
            }

            .cm-btn-decline {
              @extend .btn-outline-medium;

              order: 1;
              margin-right: 0.5rem;
              margin-left: auto;
            }
            .cm-btn-accept-all {
              @extend .btn-primary;

              order: 3;
              margin-left: 0.5rem;
              margin-right: auto;
            }
            .cm-btn-accept {
              @extend .btn-outline-medium;

              order: 2;
            }
          }

          .cm-powered-by {
            text-align: center;
          }
        }
      }
    }
  }
}
