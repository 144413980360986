@use "sass:map";

table {
  hyphens: auto;
  word-break: keep-all;
  overflow-wrap: normal;
}

.thead-no-border > thead tr th,
.thead-no-border > table thead tr th {
  border-top: none;
  border-bottom: none;
}

.thead-sticky {
  > thead {
    position: sticky;
    background: white;
    top: 0;
    box-shadow: inset 0 -1px 0 0 $table-border-color;
    z-index: 4;

    @include media-breakpoint-down(md) {
      top: $ci-header-height;
    }
  }
}

@mixin table-responsive-inner-shadow() {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    linear-gradient(to left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  background-size: 5px auto, 5px auto;
  background-position: left top, right top;
  background-repeat: no-repeat;
}

.table-responsive {
  @each $breakpoint in map.keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        &.inner-shadow {
          @include table-responsive-inner-shadow;
        }
      }
    }
  }
}

table.col-pairs {
  table-layout: fixed;

  td {
    width: 50%;
  }
}
