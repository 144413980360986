.v-select,
.vs__dropdown-toggle,
.vs__selected-options,
.vs__actions {
  height: $input-height;
}

.v-select {
  border-bottom: 2px solid $input-bg;
  border-radius: $border-radius;

  &.invalid {
    border-bottom-color: $danger;
  }
}

.vs__selected {
  white-space: nowrap;
  width: calc(100% - 56px);
  display: block;

  @include overflow-ellipsis;
}

.vs__dropdown-toggle {
  height: calc(calc(#{$input-height}) - 2px);
  border: none !important;
  background: $input-bg;
}

.vs__dropdown-menu {
  @extend .card;
  @extend .shadow;
}

.vs--single .vs__selected {
  padding: $input-padding-y $input-padding-x;
  height: $input-height;
  margin: 0;
}

.vs__actions {
  @extend .select-icon;

  height: calc(1.5em + 2rem - 1.25rem) !important;
  pointer-events: initial !important;
  z-index: 2;
}

.vs__selected-options {
  z-index: 1;
  width: 100%;
}

.vs__fade-enter-active,
.vs__fade-leave-active {
  transition: none;
}

.v-select.hide-tags {
  .vs__selected {
    display: none !important;
  }
}
