@use "sass:color"; @import "@frontend/vars.scss";
.ci-tabs {
  &.no-border {
    .nav-tabs {
      border-bottom: none;

      @include media-breakpoint-up(lg) {
        .nav-item .nav-link:hover {
          box-shadow: inset 0 -1px 0 0 $cap-gray-200;
        }
      }
    }
  }

  .tab-nav-wrapper {
    scrollbar-width: none;
    overflow: auto;
    overflow-y: hidden;
    height: 3rem;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .nav-tabs {
    border-bottom: 1px solid $cap-gray-200;
    display: inline-flex;
    flex-wrap: nowrap;
    min-width: 100%;
  }

  .nav-item {
    margin-top: 0;
    overflow: visible;

    .nav-link {
      padding: ($spacer * 0.75) $spacer;
      font-weight: $font-weight-semi;
      color: $text-muted;
      border: none;
      white-space: nowrap;
      text-transform: uppercase;

      &:hover {
        background: rgba(190, 195, 206, 0.15);
        border: none;
      }

      &.active {
        color: $cap-primary-600;
        background: transparent;
        box-shadow: inset 0 -2px 0 0 $cap-primary-600 !important;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &.handheld-alternative .nav-tabs {
      display: none;
    }
  }
}
