@use "sass:color"; @import "@frontend/vars.scss";
.ci-notice-bar {
  bottom: 0;
  position: fixed;
  min-height: $ci-notice-bar-height;
  width: 100%;
  background-color: $cap-warning-400;
  z-index: 1041;

  &__wrapper {
    max-width: $ci-max-page-width;
    margin: 0 auto;
  }
}

@include media-breakpoint-down(sm) {
  .ci-notice-bar {
    min-height: $ci-notice-bar-height-sm;
  }
}
