@use "sass:color"; @import "@frontend/vars.scss";
#modal-reminder {
  .signup-success-decoration-circle {
    position: absolute;
    background: linear-gradient(to bottom, $cap-primary 25%, #39a991 75%);
    height: 500px;
    width: 500px;
    bottom: -403px;
    left: -346px;
    border-radius: 50%;
  }

  .signup-success-decoration-circle-second {
    position: absolute;
    background: rgba($primary, 0.2);
    height: 500px;
    width: 500px;
    bottom: -337px;
    left: -375px;
    border-radius: 50%;
  }
}
