.guest-print-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 30000;
  background: rgba(255, 255, 255, 0.8);
  -webkit-print-color-adjust: exact;
}
