.checkmark-circle--animated {
  .checkmark-circle__path,
  .checkmark-circle__circle {
    fill: none;
    stroke: $primary;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .checkmark-circle__path {
    animation: pathanim 4s linear forwards;
  }

  .checkmark-circle__circle {
    animation: circleanim 4s linear forwards;
  }

  @keyframes circleanim {
    0% {
      stroke-dashoffset: 1;
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes pathanim {
    0% {
      stroke-dashoffset: 1;
    }
    30% {
      stroke-dashoffset: 1;
    }
    70% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
}
