@use "sass:color"; @import "@frontend/vars.scss";
.header:hover {
  :not(.invisible) {
    .sort-arrow {
      display: initial;
      visibility: visible !important;
    }
  }
}

.header {
  min-height: 36px;
}

.button-header {
  :not(&.invisible) {
    min-width: 32px;
  }
}

.sort-arrow.active {
  display: initial;
  visibility: visible !important;
}

.invisible {
  display: none;
}
