@use "sass:color"; @import "@frontend/vars.scss";
.ci-toaster {
  display: block;
  position: fixed;
  left: 0.5rem;
  right: 0.5rem;
  margin: 0;
  padding: 0;
  height: 0;
  overflow: visible;
  top: 4.25rem;
  z-index: 1090;

  &:empty {
    display: none !important;
  }

  .toaster-slot {
    max-width: 20rem;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0;
    margin-left: auto;
  }
}
