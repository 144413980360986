@use "sass:color"; @import "@frontend/vars.scss";
.ci-highcharts--wrapper {
  &.disabled {
    position: relative;
  }
  .ci-highcharts--disabled {
    position: absolute;
    z-index: 2;
  }
}
.ci-highcharts {
  &[disabled] {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }
  .highcharts-reset-zoom {
    .highcharts-button-box {
      stroke: transparent !important;
      fill: var(--global-color-gray-100);
    }
  }
  .highcharts-tooltip-box {
    .color-indicator {
      width: 3px;
      margin-left: 0.5rem;
      align-self: stretch;
    }
  }
  .highcharts-range-selector-group {
    .highcharts-input-group {
      .highcharts-range-label {
        text {
          transform: translateY(4px);
        }
      }
      .highcharts-range-input {
        rect {
          fill: $light;
        }
      }
    }
  }
  .highcharts-legend-item {
    .highcharts-graph {
      transform: translate(32px, 13px) rotate(90deg);
    }
    > span {
      transform: translateY(2px) translateX(-13px);
    }

    &.highcharts-area-series rect {
      x: -2;
      width: 2px;
      height: 21px;
    }
  }

  @media not print {
    @include media-breakpoint-down(sm) {
      .highcharts-navigator,
      .highcharts-scrollbar,
      .highcharts-navigator-series,
      .highcharts-navigator-xaxis,
      .highcharts-input-group {
        display: none !important;
      }
    }
  }

  @media print {
    .highcharts-input-group {
      display: none !important;
    }

    .highcharts-navigator,
    .highcharts-navigator-xaxis,
    .highcharts-navigator-series {
      opacity: 0.2;
      -webkit-print-color-adjust: exact;
    }
  }

  .highcharts-range-selector-buttons {
    .highcharts-button {
      rect {
        stroke: $cap-gray-600;
        stroke-width: 1;
      }

      &:nth-child(2) rect {
        stroke-dasharray: 0 58 12 100;
      }
      &:nth-child(3) rect {
        stroke-dasharray: 0 58 12 100;
      }
      &:nth-child(4) rect {
        stroke-dasharray: 0 66 12 100;
      }
      &:nth-child(5) rect {
        stroke-dasharray: 0 49 12 100;
      }
      &:nth-child(6) rect {
        stroke-dasharray: 0 49 12 100;
      }
      &:nth-child(7) rect {
        stroke-dasharray: 0 49 12 100;
      }
      &:nth-child(8) rect {
        stroke-width: 0;
      }
    }
  }
}
