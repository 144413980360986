.article-feed {
  @include media-breakpoint-up(lg) {
    .popular-articles {
      max-width: 330px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      padding-bottom: 0;
      margin-bottom: 1rem;

      &:after {
        content: "";
      }
    }

    &-text {
      display: flex;

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin: 0 0 1rem;
        font-size: 1.25rem;
      }
    }
  }

  &__options {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    padding-bottom: 0.75em;

    .tab-content {
      margin: -7px 0 0;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      position: static;
      overflow-x: auto;
      overflow-y: hidden;
      padding-bottom: 0;
      height: auto;

      .tab-content {
        margin: -5px 0 0;
      }
    }
  }
}
